import { axiosGet, axiosPost } from './service';

export async function getPromotions(module) {
    return axiosGet('/Affiliate/Promotions?module=' + module + '&affiliateType=' + localStorage.getItem('app.profile.earningsModel'), {
        startIndex: 1,
        endIndex:20,
    });
}

export async function checkPromotionParticipant(promotionId) {
    return axiosGet('/Affiliate/Promotion/Application', {
        promotionId: promotionId
    });
}

export async function addPromotionParticipant(participantModel) {
    return axiosPost('/Affiliate/Promotion/Participant', participantModel)
}

export async function getMemberSiteRedirect() {
    return axiosGet('/Affiliate/Promotion/GetMemberSiteLink'); 
}