import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  mobile: false,
  screensize: 'lg',
  screenwidth: 1000
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SETSCREEN_SIZE:
      return updateObject(state, {
        screensize: action.screensize,
        screenwidth: action.screenwidth,
        mobile: action.mobile
      });
    default:
      return state;
  }
};

export default reducer;
