import { axiosGet, axiosPost, axiosPut, axiosPatch } from './service';

export async function getAffiliateBank() {
    return axiosGet('/Bank/GetAffiliateBank');
}

export async function deleteBankAccount(bankID) {
    return axiosPost('/BankAccount/Delete', null, { bankID: bankID });
}

export async function getBankList(countryCode) {
    return axiosGet('/Bank/GetBankByCountry', { countryCode: countryCode });
}

export async function getCountryList() {
    return axiosGet('/Common/GetCountryList');
}

export async function addBankAccount(model) {
    return axiosPost('/BankAccount/Register', model);
}

export async function editBankAccount(model) {
    return axiosPost('/BankAccount/Update', model);
}

export async function getPaymentMethodList() {
    return axiosGet('/Withdrawal/PayoutMethod');
}

export async function getWalletList() {
    return axiosGet('/Cryptocurrency/Wallet');
}

export async function getCryptocurrencyList() {
    return axiosGet('/Cryptocurrency');
}

export async function getCryptocurrencyCodeByID(cryptocurrencyID) {
    return axiosGet('/Cryptocurrency/GetCryptocurrencyCodeByID', { cryptocurrencyID: cryptocurrencyID });
}

export async function addCryptocurrencyWallet(model) {
    return axiosPost('/Cryptocurrency/Wallet', model);
}

export async function editCryptocurrencyWallet(model, walletID) {
    return axiosPut('/Cryptocurrency/Wallet/' + walletID, model);
}

export async function deleteCryptocurrencyWallet(walletID) {
    return axiosPatch('/Cryptocurrency/Wallet/' + walletID);
}

export async function getCashReceiverList() {
    return axiosGet('/Bank/CashReceiver');
}

export async function addCashReceiver(model) {
    return axiosPost('/Bank/CashReceiver', model);
}

export async function editCashReceiver(model) {
    return axiosPut('/Bank/CashReceiver', model);
}

export async function deleteCashReceiver(receiverID) {
    return axiosPatch('/Bank/CashReceiver/' + receiverID);
}
