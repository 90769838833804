import React, { Component } from 'react';
import './PopUp.css';
import './bootstrap-3.3.2-custom.min.css';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from '../../store/actions';
import { messages } from './Translate';
import LoadComponent from '../../hoc/LoadComponent';
import Tnc from '../Help/Tnc';
import { redirectPage } from '../../utils/common';
import { Link } from 'react-router-dom';
import USDTDisclaimer from './USDTDisclaimer';
import MemberSearchPopUp from './MemberSearchPopUp';

const ForgotPassword = LoadComponent(() => {
    return import('../ForgotPassword/ForgotPasswordForm');
});
const SecurityQuestionForm = LoadComponent(() => {
    return import('../PasswordReset/SecurityQuestion');
})
const PasswordResetForm = LoadComponent(() => {
    return import('../PasswordReset/PasswordReset');
})
const SecretKeyForm = LoadComponent(() => {
    return import('../PasswordReset/SecretKey');
})
const WithdrawalBankAccountForm = LoadComponent(() => {
    return import('../PasswordReset/WithdrawalBankAccount');
})
const SuccessForm = LoadComponent(() => {
    return import('../PasswordReset/Success');
})
const ErrorForm = LoadComponent(() => {
    return import('../PasswordReset/Error');
})
const Registration = LoadComponent(() => {
    return import('../Registration/RegistrationForm');
});
const RegistrationSuccessful = LoadComponent(() => {
    return import('../Registration/RegistrationSuccessful');
});
const ChangePassword = LoadComponent(() => {
    return import('../ChangePassword/ChangePasswordForm');
});
const LoginForm = LoadComponent(() => {
    return import('../Login/LoginForm');
});
const FirstTimeLogin = LoadComponent(() => {
    return import('../FirstTimeLogin/FirstTimeLoginForm');
});
const BankDetailsView = LoadComponent(() => {
    return import('../BankAccounts/BankDetailsView');
});
const WalletDetailsView = LoadComponent(() => {
    return import('../CryptocurrencyWallet/WalletDetailsView');
});
const CashReceiverDetailsView = LoadComponent(() => {
    return import('../BankForm/CashReceiverDetailsView');
});
const BankForm = LoadComponent(() => {
    return import('../BankForm/BankForm');
});
const AddSubAffiliate = LoadComponent(() => {
    return import('../SubAffiliateManagementForm/AddSubAffiliate');
});
const EditSubAffiliate = LoadComponent(() => {
    return import('../SubAffiliateManagementForm/EditSubAffiliate');
});
const TrackerLinkManagementForm = LoadComponent(() => {
    return import('../TrackerLinkManagementForm/TrackerLinkManagementForm');
});
const CommissionPopUp = LoadComponent(() => {
    return import('../Commission/CommissionPopUp');
});
const ReportByMemberPopUp = LoadComponent(() => {
    return import('../ReportByMembers/ReportByMemberDetails');
});
const OTP = LoadComponent(() => {
    return import('../OTP/OTP');
});
const BannerPopUp = LoadComponent(() => {
    return import('../Banner/BannerPopUp');
});
const CashCardSuccessful = LoadComponent(() => {
    return import('../CashCard/CashCardSuccessful');
});
const CashCardDetails = LoadComponent(() => {
    return import('../CashCard/CashCardDetails');
});
const WithdrawalRequestSent = LoadComponent(() => {
    return import('../Withdrawal/WithdrawalRequestSent');
});
const PromotionDetails = LoadComponent(() => {
    return import('../Promotions/PromotionDetails');
});
const ResetPasswordForm = LoadComponent(() => {
    return import('../ResetPassword/ResetPasswordForm');
});
const EnforceChangePasswordForm = LoadComponent(() => {
    return import('../EnforceChangePassword/EnforceChangePasswordForm');
});
const PopUpMessage = LoadComponent(() => {
    return import('./PopUpInfo');
});

const LinePopUp = LoadComponent(() => {
    return import('../Help/LinePopUp');
});

const ZaloPopUp = LoadComponent(() => {
    return import('../Help/ZaloPopUp');
});

const SecurityQnAForm = LoadComponent(() => {
    return import('../SecurityQnA/SecurityQnAForm');
});

const ContactUs = LoadComponent(() => {
    return import('../home/ContactUs');
});

const PopUpIDRMarketClosure = LoadComponent(() => {
    return import('./PopUpIDRMarketClosure');
});

const TransferSuccessful = LoadComponent(() => {
    return import('../Transfer/TransferSuccess');
});

const TransferFail = LoadComponent(() => {
    return import('../Transfer/TransferFail');
});

const TransferPending = LoadComponent(() => {
    return import('../Transfer/TransferPending');
});

const TransactionHistoryDetails = LoadComponent(() => {
    return import('../TransactionHistory/TransactionHistoryDetails');
});

const ReconciliationReport = LoadComponent(() => {
    return import('../IncomeStatement/ReconciliationReport');
});

class PopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'phone'
        }
    }

    renderPopUpComponent = (key) => {
        switch (key) {
            case 'Registration':
                return <Registration closePopUp={this.props.closePopUp} regModel={this.props.param == null ? null : this.props.param.regModel} />
            case 'ForgotPassword':
                return <ForgotPassword forgotPasswordType={this.state.type} switchForgotPasswordType={this.switchType} />
            case 'ResetPassword':
                return <ResetPasswordForm />
            case 'PasswordResetForm':
                return <PasswordResetForm />
            case 'SecurityQuestionForm':
                return <SecurityQuestionForm />
            case 'SecretKeyForm':
                return <SecretKeyForm />
            case 'WithdrawalBankAccountForm':
                return <WithdrawalBankAccountForm />
            case 'SuccessForm':
                return <SuccessForm />
            case 'ErrorForm':
                return <ErrorForm />
            case 'ChangePassword':
                return <ChangePassword />
            case 'RegistrationSuccessful':
                return <RegistrationSuccessful />
            case 'Login':
                return <LoginForm />
            case 'BankDetailsView':
                return <BankDetailsView />
            case 'WalletDetailsView':
                return <WalletDetailsView />
            case 'CashReceiverDetailsView':
                return <CashReceiverDetailsView />
            case 'EditCashReceiver':
                return <BankForm type='Edit' paymentMethod={this.props.param.paymentMethod} isEdit={1} />
            case 'EditBank/Wallet':
                return <BankForm type='Edit' paymentMethod={this.props.param.paymentMethod} isEdit={1} cryptocurrencyType={this.props.param.cryptocurrencyType} />
            case 'AddBank/Wallet':
                return <BankForm type='Add' isRedirected={this.props.param.isRedirected} />
            case 'AddSubAffiliate':
                return <AddSubAffiliate closePopUp={this.props.closePopUp} />
            case 'EditSubAffiliate':
                return <EditSubAffiliate closePopUp={this.props.closePopUp} />
            case 'ViewSubAffiliate':
                return <EditSubAffiliate closePopUp={this.props.closePopUp} />
            case 'ReportByMemberPopUp':
                return <ReportByMemberPopUp memberCode={this.props.memberCode} />
            case 'AddTracker':
                return <TrackerLinkManagementForm type='Add' />
            case 'EditTracker':
                return <TrackerLinkManagementForm type='Edit' />
            case 'LMCommissionPopUp':
                return <CommissionPopUp type="LM" />
            case 'CMCommissionPopUp':
                return <CommissionPopUp type="CM" />
            case 'OTP':
                return <OTP
                    module={this.props.param.module}
                    verificationType={this.props.param.verificationType}
                    disableSwitchMethod={this.props.param.disableSwitchMethod}
                    popupCallback={this.props.param.popupCallback}
                />
            case 'OTPLogin':
                return <OTP
                    module={this.props.param.module}
                    verificationType={this.props.param.verificationType}
                    disableSwitchMethod={this.props.param.disableSwitchMethod}
                    popupCallback={this.props.param.popupCallback}
                />
            case 'BannerPopUp':
                return <BannerPopUp />
            case 'CashCardSuccessful':
                return <CashCardSuccessful />
            case 'CashCardDetails':
                return <CashCardDetails />
            case 'WithdrawalRequestSent':
                return <WithdrawalRequestSent />
            case 'PromotionDetails':
                return <PromotionDetails
                    content={this.props.param.content}
                    bannerType={this.props.param.bannerType}
                    id={this.props.param.id}
                    title={this.props.param.title}
                    joined={this.props.param.joined}
                />
            case 'TNC':
                return <Tnc isPopup={true} regModel={this.props.param.regModel} />
            case 'FirstTimeLogin':
                return <FirstTimeLogin isFirstTimeLogin={this.props.isFirstTimeLogin}
                    userName={this.props.userName}
                    email={this.props.email}
                    contact={this.props.contact} />
            case 'EnforceChangePassword':
                return <EnforceChangePasswordForm />
            case 'LinePopUp':
                return <LinePopUp type={this.props.type} label={this.props.label} description={this.props.description} />
            case 'ZaloPopUp':
                return <ZaloPopUp />
            case 'PopUpMessage':
                return <PopUpMessage message={this.props.param.message} />
            case 'UpdateSecurityQnA':
                return <SecurityQnAForm />
            case 'ContactUs':
                return <ContactUs />
            case 'PopUpIDRMarketClosure':
                return <PopUpIDRMarketClosure />
            case 'PopUpIDRMarketClosure_16March':
                return <PopUpIDRMarketClosure type='Login' />
            case 'TransferSuccessful':
                return <TransferSuccessful />
            case 'TransferFail':
                return <TransferFail />
            case 'TransferPending':
                return <TransferPending />
            case 'TransactionHistoryDetails':
                return <TransactionHistoryDetails type={this.props.type} refID={this.props.refID} />
            case 'USDTDisclaimer':
                return <USDTDisclaimer />
            case 'MemberSearchPopUp':
                return <MemberSearchPopUp />
            case 'ReconciliationReport':
                return <ReconciliationReport selectedMonth={this.props.param.selectedMonth} details={this.props.param.details} />
            default:
                return null;
        }
    }

    switchType = () => {
        this.setState({
            type: (this.state.type === 'email' ? 'phone' : 'email')
        })
    }

    getTitle = () => {
        try {
            switch (this.props.popUpComponent) {
                case 'BankDetailsView': return this.props.param.bankName;
                case 'WalletDetailsView': return this.props.param.walletName;
                case 'CashReceiverDetailsView': return this.props.param.receiverName;
                case 'PromotionDetails': return this.props.param.title;
                case 'BannerPopUp': return <br />;
                case 'LMCommissionPopUp':
                case 'CMCommissionPopUp': return this.props.intl.formatMessage(messages.Commission_Page_Title);
                case 'FirstTimeLogin': return this.props.intl.formatMessage(messages.FirstLogin);
                case 'ReportByMemberPopUp': return <br />;
                case 'ContactUs': return <br />;
                case 'USDTDisclaimer': return this.props.intl.formatMessage(messages.USDT_Disclaimer);
                case 'MemberSearchPopUp': return <br />;
                case 'WithdrawalRequestSent': return this.props.param.title ?
                    this.props.intl.formatMessage(messages.USDT_Disclaimer) : this.translate(this.props.popUpComponent);
                case 'OTPLogin': return this.translate('OTP');
                case 'ReconciliationReport': return <br />;
                case 'PasswordResetForm': return this.props.intl.formatMessage(messages.ResetPassword);
                case 'SecurityQuestionForm': return this.props.intl.formatMessage(messages.ResetPassword);
                case 'SecretKeyForm': return this.props.intl.formatMessage(messages.ResetPassword);
                case 'WithdrawalBankAccountForm': return this.props.intl.formatMessage(messages.ResetPassword);
                case 'SuccessForm': return this.props.intl.formatMessage(messages.ResetPassword);
                case 'ErrorForm': return this.props.intl.formatMessage(messages.ResetPassword);
                default: return this.translate(this.props.popUpComponent);
            }
        } catch (e) {
            console.error(e);
            return <br />;
        }
    }

    translate = (key) => {
        return messages[key] ? this.props.intl.formatMessage(messages[key]) : key;
    }

    getPopUpMaxWidth = () => {
        switch (this.props.popUpComponent) {
            case 'LMCommissionPopUp': return '1050px';
            case 'CMCommissionPopUp':
            case 'TNC':
            case 'BannerPopUp': return '1000px';
            case 'PromotionDetails': return '800px';
            case 'ReportByMemberPopUp': return '1280px';
            case 'ContactUs': return '700px';
            case 'ReconciliationReport': return '840px';
            default: return '520px';
        }
    }

    getClosePopUp = () => {
        switch (this.props.popUpComponent) {
            case 'FirstTimeLogin': return this.props.closePopUpLogout;
            case 'UpdateSecurityQnA': return this.props.closePopUpLogout;
            case 'TransferSuccessful': return this.props.closePopUpTransfer;
            case 'TransferFail': return this.props.closePopUpTransfer;
            case 'TransferPending': return this.props.closePopUpTransfer;
            default: return this.props.closePopUp;
        }
    }

    render() {
        return (
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                title={this.getTitle()}
                visible={this.props.showPopUp}
                onCancel={this.getClosePopUp()}
                header={null}
                footer={null}
                width='100%'
                style={{ maxWidth: this.getPopUpMaxWidth(), padding: '10px', margin: 'auto' }}
            >
                <React.Fragment key='globalPopup'>
                    {this.renderPopUpComponent(this.props.popUpComponent)}
                </React.Fragment>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        showPopUp: state.popUp.showPopUp,
        popUpComponent: state.popUp.component,
        param: state.popUp.param,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closePopUp: () => dispatch(actions.closePopUp()),
        closePopUpLogout: () => dispatch(actions.closePopUpLogout()),
        closePopUpTransfer: () => dispatch(actions.closePopUpTransfer())
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PopUp)
);