import { Modal } from 'antd';

export const alertInfo = (title, content) => {
  Modal.info({
    title: title,
    content: content
  });
}

export const alertSuccess = (title, content) => {
  Modal.success({
    title: title,
    content: content,
  });
}

export const alertSuccessCallBack = (title, content, okCallBack) => {
  Modal.success({
    title: title,
    content: content,
    onOk: okCallBack,
  });
}

export const alertError = (title, content) => {
  Modal.error({
    title: title,
    content: content,
  });
}

export const alertWarning = (title, content) => {
  Modal.warning({
    title: title,
    content: content,
  });
}

export const alertConfirm = (title, content, okCallBack, okText, cancelText) => {
  Modal.confirm({
    title: title,
    content: content,
    onOk: okCallBack,
    okText: okText,
    cancelText: cancelText
  });
}