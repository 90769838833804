import languages from '../components/Language/Languages.json';
import { localeArray } from '../constant/locale.js';
import { deleteCache } from './common.js'
import { isMarketClosingDate } from '../utils/formatter';
import { getAffiliateLocale } from '../services/affiliate';
import { setLocalStorage_locale } from '../utils/localStorage';

export const handleChangeLocale = value => {

    const locale = window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX];
    const host = window.location.host;
    if (navigator.onLine) {
        window.location.href = window.location.href.replace(
            `${host}${process.env.REACT_APP_SUB_FOLDER}/${locale}`,
            `${host}${process.env.REACT_APP_SUB_FOLDER}/${value}`
        );

        try {
            deleteCache();
        } catch (e) {
            console.log('No cache');
        }

    } else {
        var selectedLanguage = `${value}`;

        if (locale !== selectedLanguage) {

            const lastLanguage = locale.substring(0, 2);

            switch (lastLanguage) {
                case 'zh': return window.alert('您现在处于离线状思，丝兝许在语言之间切杢。');
                case 'th': return window.alert('สถานะของคุณออฟไลน์ ไม่อนุฝาติให้เปลี่ยนภาษา.');
                case 'id': return window.alert('Anda sedang offline saat ini dan Anda tidak diijinkan untuk mengganti bahasa lain.');
                case 'vi': return window.alert('Bạn đang offline, chuyển đổi ngôn ngữ không được cho phép.');
                default: return window.alert('Your are offline now, switch among languages is not allowed.');
            };

        }

    }


};

export const getLocale = () => {
    const locale = window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX];

    if (isMarketClosingDate()) {
        var localeArrayExcludeID = localeArray.filter(item => item != item.endsWith('-id'))

        return localeArrayExcludeID.includes(locale) ? locale : 'zh-cn';
    }
    else {
        return localeArray.includes(locale) ? locale : 'zh-cn';
    }
}

export const loadSelectedFlag = () => {
    const locale = window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX];

    const languageArray = languages;

    if (isMarketClosingDate()) {
        languages.filter(item => item != item.code.endsWith('-id'))
    }

    for (let i = 0; i < languageArray.length; i++) {
        if (languageArray[i].code === locale) {
            return languageArray[i].flag
        }
    }
};

export const loadSelectedLang = () => {
    const locale = window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX];

    const languageArray = languages;

    if (isMarketClosingDate()) {
        languages.filter(item => item != item.code.endsWith('-id'))
    }

    for (let i = 0; i < languageArray.length; i++) {
        if (languageArray[i].code === locale) {
            return languageArray[i].language
        }
    }
};

export const localeAuth = (urlLocale) => {
    var localePathName = window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX];
    if (urlLocale === '' || !localeArray.includes(urlLocale))
    {
        getAffiliateLocale()
            .then(response => {
                //Success Response
                if (response.status === 200)
                {
                    urlLocale = response.data.result;
                    setLocalStorage_locale(urlLocale);
                    //before login
                    if (localStorage.getItem('app.token') === null)
                    {
                        if (isMarketClosingDate())
                        {
                            if (window.location.href.toLowerCase().includes('passwordreset'))
                            {
                                localStorage.setItem('PasswordResetUrl', window.location.href);
                                const urlParams = new URLSearchParams(window.location.search);
                                const id = urlParams.get('id');
                                const affCode = urlParams.get('aff');
                                const tokenValue = urlParams.get('token');
                                localStorage.setItem('TokenId', id);
                                localStorage.setItem('AffCode', affCode);
                                localStorage.setItem('TokenValue', tokenValue);
                                window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}`
                            }
                            else{
                                var localeArrayExcludeID = localeArray.filter(item => item != item.endsWith('-id'))
                                if (localeArrayExcludeID.includes(urlLocale))
                                    window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}`
                                else
                                    window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}/error/notfound`;
                            }
                        }
                        else
                        {
                            if (localeArray.includes(urlLocale))
                                window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}`
                            else
                                window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}/error/notfound`;
                        }
                    }
                    //after login
                    else
                        if (localePathName === '' || localePathName === undefined)
                            window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}`;
                        else
                            return true;
                }
                //Fail Response
                else
                    window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/zh-cn`;
            })
            .catch(error => {
                if (error && error.status !== 401 && error.status !== 403 && error.status !== 503) {
                    window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}/error/notfound`;
                }
            })
    }
    else
    {
        //before login
        if (localStorage.getItem('app.token') === null)
        {
            if (isMarketClosingDate())
            {
                if (window.location.href.toLowerCase().includes('passwordreset'))
                {
                    localStorage.setItem('PasswordResetUrl', window.location.href);
                    const urlParams = new URLSearchParams(window.location.search);
                    const id = urlParams.get('id');
                    const affCode = urlParams.get('aff');
                    const tokenValue = urlParams.get('token');
                    localStorage.setItem('TokenId', id);
                    localStorage.setItem('AffCode', affCode);
                    localStorage.setItem('TokenValue', tokenValue);
                    window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}`
                }
                else{
                    var localeArrayExcludeID = localeArray.filter(item => item != item.endsWith('-id'))
                    if (localeArrayExcludeID.includes(urlLocale))
                    {
                        if (localePathName === '' || localePathName === undefined)
                            window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}`
                        else
                            return true;
                    }
                    else
                        window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}/error/notfound`;
                }
                
            }
            //after login
            else
            {
                if (localeArray.includes(urlLocale))
                {
                    if (localePathName === '' || localePathName === undefined)
                        window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}`
                    else
                        return true;
                }
                else
                    window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}/error/notfound`;
            }
        }
        //after login
        else
        {
            if (localePathName === '' || localePathName === undefined)
                window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}`;
            else
                return true;
        }
    }
    return false;
};