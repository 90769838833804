import { axiosGet, axiosPost } from './service';

export async function getOTPSettings(otpModule) {
    return axiosGet('/OTP/Affiliate/Setting', {
        module: otpModule
    });
}

export async function sendOTP(otpModule, model) {
    return axiosPost('/OTP/' + otpModule, model);
}

export async function resendOTP(otpModule, model) {
    return axiosPost('/OTP/' + otpModule + '/Resend', model);
}

export async function verifyOTP(otpModule, model) {
    return axiosPost('/OTP/' + otpModule + '/Verify', model);
}

export async function reverifyOTP(otpModule, model) {
    return axiosPost('/OTP/' + otpModule + '/Reverify', model);
}