import axios from 'axios';
import { updateObject } from '../utils/common';
import { getLocale } from '../utils/locale';
import { RegexPattern } from '../constant/regexPattern';

const sourceParam = {
    'api-version': process.env.REACT_APP_API_VERSION,
    brand: process.env.REACT_APP_API_BRAND,
    buCode: process.env.REACT_APP_API_BRAND,
    Platform: 'Desktop'
}

const getBFF_API_URL = () => {
    var protocol  = window.location.protocol;
    var origin = window.location.hostname;
    var originArrayList = origin.split('.');
    var regex = new RegExp(RegexPattern.IP);
    var env = process.env.REACT_APP_API_ENVIRONMENT;

    try{
        if (regex.test(origin) || origin == 'localhost')
        {
            return process.env.REACT_APP_API_SERVER;
        }
        else if (env.includes("SL"))
        {
            return protocol + '//lmpregateway' + '.' + originArrayList[originArrayList.length - 2] + '.' + originArrayList[originArrayList.length - 1];
        }
        else
        {
            return protocol + '//lmgateway' + '.' + originArrayList[originArrayList.length - 2] + '.' + originArrayList[originArrayList.length - 1] ;
        }
    }
    catch{
        console.log("getBFF_API_URL-default");
        return process.env.REACT_APP_API_SERVER;
    }
}

const getSourceHeader = () => {
    return {
        Authorization: localStorage.getItem('app.token') === null ? '' : `${process.env.REACT_APP_API_TOKEN_TYPE} ${localStorage.getItem('app.token')}`,
        Culture: getLocale()
    };
}

export const axiosGet = (url, param) => {
    return axios
        .get(getBFF_API_URL() + url, {
            params: param ? updateObject(sourceParam, param) : sourceParam,
            headers: getSourceHeader()
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error.response;
        });
}

export const axiosPost = (url, body, param) => {
    return axios
        .post(getBFF_API_URL() + url, body, {
            params: param ? updateObject(sourceParam, param) : sourceParam,
            headers: getSourceHeader()
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error.response;
        });
}

export const axiosPut = (url, body, param) => {
    return axios
        .put(getBFF_API_URL() + url, body, {
            params: param ? updateObject(sourceParam, param) : sourceParam,
            headers: getSourceHeader()
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error.response;
        });
}

export const axiosPatch = (url, body, param) => {
    return axios
        .patch(getBFF_API_URL() + url, body, {
            params: param ? updateObject(sourceParam, param) : sourceParam,
            headers: getSourceHeader()
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error.response;
        });
}

export const axiosDelete = (url, param) => {
    return axios
        .delete(getBFF_API_URL() + url, {
            params: param ? updateObject(sourceParam, param) : sourceParam,
            headers: getSourceHeader()
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error.response;
        });
}

export const axiosDownload = (url, body, param, type) => {
    return (type === 'GET') ?
        axios
            .get(getBFF_API_URL() + url, {
                responseType: "blob",
                params: param ? updateObject(sourceParam, param) : sourceParam,
                headers: getSourceHeader()
            })
            .then(response => {
                return response;
            })
            .catch(error => {
                throw error.response;
            }) :
        axios
            .post(getBFF_API_URL() + url, body, {
                responseType: "blob",
                params: param ? updateObject(sourceParam, param) : sourceParam,
                headers: getSourceHeader()
            })
            .then(response => {
                return response;
            })
            .catch(error => {
                throw error.response;
            });
}



