import React from 'react';
import { injectIntl } from 'react-intl';
import { messages } from './Translate';
import '../Form/Form.css';
import { Row, Col, DatePicker } from 'antd';
import moment from 'moment';

class MemberRegistrationDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: moment(),
        }
    }

    onStartDateChange = (date) => {

        this.setState({
            startDate: date === null ? moment('1753-01-01').format('YYYY-MM-DD') : moment(date)
        }, () => this.props.onDateUpdate(this.state.startDate, this.state.endDate));
    }

    onEndDateChange = (date) => {
        this.setState({
            endDate: moment(date)
        }, () => this.props.onDateUpdate(this.state.startDate, this.state.endDate));
    }    

    translate = (key) => {
        return messages[key] ? this.props.intl.formatMessage(messages[key]) : key;
    }

    render() {
        return (
            <>
            <Row>
                <Col className='label-wrapper'>
                    <label className='label'>{this.translate('RegistrationDate')}</label>
                </Col>
            </Row>
            <Row>
                    <Col>
                        <DatePicker
                            disabledDate={(current) => current > moment() || current > moment(this.state.endDate).add(1,'days')}
                            format={'MMM DD, YYYY'}
                            onChange={this.onStartDateChange} />  
                        &nbsp; - &nbsp;
                        <DatePicker
                            disabledDate={(current) => current > moment() || current < moment(this.state.startDate).subtract(1,'days')}
                            value={this.state.endDate}
                            format={'MMM DD, YYYY'}
                            onChange={this.onEndDateChange}
                            allowClear={false}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default injectIntl(MemberRegistrationDate);