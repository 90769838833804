import * as actions from '../../constant/reduxActionTypes';

export const openWelcomePopUp = () => {
  return {
    type: actions.OPEN_WELCOME_POPUP
  };
};

export const closeWelcomePopUp = (e) => {
  return {
    type: actions.CLOSE_WELCOME_POPUP
  };
};

