export const Cookie = {
  Create: (name, value, days) => {
    let expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    } else {
      expires = '';
    }
    let domain = document.location.hostname;
    let domainSplits = domain.split('.');
    let isIPDomain = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
      domain
    );
    if (!(domainSplits.length === 1) && !isIPDomain) {
      if (domainSplits.length >= 3) {
        domainSplits = domainSplits.slice(-2);
        domain = '.' + domainSplits.join('.');
      } else {
        domain = '.' + domainSplits.join('.');
      }
    }
    var encodeValue = encodeURIComponent(value);

    document.cookie = name + '=' + encodeValue + expires + '; path=/; domain=' + domain;
    // document.cookie = name + '=' + value + expires + '; path=/;';
  },
  Delete: cookieName => {
    Cookie.Create(cookieName, '', -1);
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  },
  Get: cookieName => {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  GetCookieKeyValue: cookieName => {
    let aff = Cookie.Get(cookieName);
    return aff ? aff.split('=')[1] : null;
  }
};