import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Message from '../components/Error/Message';
import '../components/Error/Error.css'; 
import {getImage} from '../utils/common';

class AccessDenied extends Component {
constructor(){
  super();
  this.state = {
    width:window.innerWidth,
  };
}
  redirectToHome = () => {
    window.location.href = process.env.REACT_APP_SUB_FOLDER;
  }

  componentWillMount(){
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render(){
    const {width} = this.state;
    const isMobile = width <=800;
    const errorType = 'AccessDenied';

    if(isMobile){
    return (
      <React.Fragment>
      <div>
      <div className="error_bg_accessdenied">
        <section className="error_desc"> 
           <img className="error_logo" src={getImage('error/Fun88-Affiliate_CN.png')} alt="" />   
        </section> 
          
        <div>
          <section className="error_contentChar">    
            <img className="error_char" src={getImage('error/AccessDenied/AccessDeniedChar-Mobile.png')} alt=""></img>
            <Message errorType={errorType}/> 
          </section> 
         </div>
      </div>
    </div>
    </React.Fragment>
    )
    }
    else{
      return(
        <React.Fragment>
        <div>
        <div className="error_bg_accessdenied">
          <section className="error_desc">
             <img className="error_logo" src={getImage('error/Fun88-Affiliate_CN.png')} alt="" />   
             <Message errorType={errorType}/> 
          </section>
            <section className="error_contentChar">    
              <img className="error_char" src={getImage('error/AccessDenied/AccessDeniedChar.png')} alt=""></img>
            </section> 
        </div>
      </div>
      </React.Fragment>
      )
    }
    
  }
};

export default injectIntl(AccessDenied);
