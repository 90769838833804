import React from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { messages } from './Translate';
import '../Form/Form.css';

class MemberCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    translate = (key) => {
        return messages[key] ? this.props.intl.formatMessage(messages[key]) : key;
    }

    render() {
        const {
            form: { getFieldDecorator }
        } = this.props;

        return (
            <Form.Item                 
                className='label-wrapper'
                label={<label className='label'>{this.translate('MemberCode')}</label>}
                colon={false}
                {... this.props.formItemLayout}
            >
                {
                    getFieldDecorator('MemberCode', {
                        initialValue: this.props.initialValue
                    })(<Input onChange={this.props.onMemberCodeUpdate} />)
                }
            </Form.Item>
        );
    }
}

export default injectIntl(MemberCode);