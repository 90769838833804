import { axiosGet, axiosPost, axiosDelete, axiosPatch, axiosPut } from './service';

export async function getAffiliateLanguage(body) {
    return axiosGet('/Affiliate/GetAffiliateLanguage');
}

export async function getAffiliateLocale() {
    return axiosGet('/Affiliate/GetAffiliateLocale');
}

export async function postAffiliate(body) {
    return axiosPost('/Affiliate', body);
}

export async function getAffiliateProfile() {
    return axiosGet('/Affiliate');
}

export async function getParentAffiliateProfile(affiliatecode) {
    return axiosGet('/ParentAffiliate',{affiliatecode: affiliatecode});
}

export async function getAffiliateBalance() {
    return axiosGet('/Affiliate/Balance');
}

export async function getCheckSignUp(regModel) {
    return axiosGet('/Affiliate/CheckSignUp', {
        Language: regModel.language,
        NationCode: regModel.nationCode,
        FullName: regModel.fullName,
        userName: regModel.userName,
        Password: regModel.password,
        Email: regModel.email,
        ContactPrefix: regModel.contactPrefix,
        Telephone: regModel.telephone,
        ParentAffiliateCode: regModel.ParentAffiliateCode
    });
}

export async function getCheckIsFullNameExisted(fullName, Language, nationCode) {
    return axiosGet('/Affiliate/ValidateExistedFullNameValue', {
        Language: Language,
        NationCode: nationCode,
        FullName: fullName
    });
}

export async function getCheckIsUserNameExisted(userName, Language, nationCode) {
    return axiosGet('/Affiliate/ValidateExistedUserNameValue', {
        Language: Language,
        NationCode: nationCode,
        userName: userName
    });
}

export async function getCheckParentAffiliateCodeExisted(affiliateCode) {
    return axiosGet('/Affiliate/ValidateExistedParentAffiliateCode', {
        parentAffiliateCode: affiliateCode
    });
}

export async function getCheckForgetPasswordInfo(username, email) {
    return axiosPost('/Affiliate/ForgotPasswordGenerateLink', null, {
        userName: username,
        email: email
    });
}

export async function checkEmailExistence(email) {
    return axiosGet('/Affiliate/ValidateExistedEmailValue', {
        email: email
    });
}

export async function checkPhoneExistence(prefix, phone) {
    return axiosGet('/Affiliate/ValidateExistedPhoneValue', {
        prefix: prefix,
        phone: phone
    });
}

export async function getAffiliateTextLink(textLinkTypeId, startIndex, endIndex) {
    return axiosGet('/Affiliate/TextLink', {
        textLinkTypeID: textLinkTypeId,
        startIndex: startIndex,
        endIndex: endIndex,
    });
}

export async function getAffiliateCustomVariables() {
    return axiosGet('/Affiliate/Comm100/CustomVariables');
}

// [Start] First Login Get & Post Methods
export async function getCheckContact(model) {
    return axiosPost('/FirstTimeLogin/Validate', model);
}

export async function getCheckOldPassword(model) {
    return axiosPost('/FirstTimeLogin/ValidateOldPassword', model);
}

export async function submitFirstLogin(model) {
    return axiosPost('/FirstTimeLogin', model);
}

export async function submitFirstLogin_QnA(model) {
    return axiosPost('/FirstTimeLogin_QnA', model);
}

export async function submitSecretQnA(model) {
    return axiosPost('/SecretQnA', model);
}
// [End] First Login Get & Post Methods

export async function getcontactprefix() {
    return axiosGet('/Common/AllContactPrefix');
}

export async function getAffiliateMenu(culture) {
    return axiosGet('/frontmember/revamp/menu/list', {
        languageCode: culture,
        isCM: localStorage.getItem('app.profile.earningsModel') === "1" ? 0 : 1,
        isShow: true,
        isLogin: true,
        roleCode: localStorage.getItem('app.profile.rolecode') == "null" ? null : localStorage.getItem('app.profile.rolecode') ,
    });
}

export async function getdatagroup(groupName, language) {
    return axiosGet('/Common/GetDataGroup', {
        groupName: groupName,
        language: language
    });
}

export async function getSecretQuestion() {
    return axiosGet('/Common/GetSecretQuestion');
}

export async function postAffiliateProfile(preferredContactTime) {
    return axiosPost('/Affiliate/PreferredContactTime', {
        preferredContactTime: preferredContactTime
    });
}

export async function updateShowWelcomePopUp() {
    return axiosPost('/Affiliate/ShowWelcomePopUp', {
        ShowWelcomePopUp: 0
    });
}

export async function offReminderPopUp(offReminder) {
    return axiosPost('/Affiliate/OffReminderPopUp', {
        offReminder: offReminder
    });
}

export async function addWebsite(model) {
    return axiosPost('/Affiliate/AddWebsite',model);
}

export async function getWebsite() {
    return axiosGet('/Affiliate/GetWebsiteByAffiliateCode');
}

export async function deleteWebsite(websiteID) {    
    return axiosPatch('/Affiliate/DeleteWebsiteByWebsiteID/' + websiteID);
}

export async function editWebsite(model) {
    return axiosPut('/Affiliate/UpdateWebsiteByWebsiteID/' + model.websiteID, model);
}

export async function getStartDayBySettlementGroupID(settlementGroupID) {
    return axiosGet('/Affiliate/SettlementGroup/' + settlementGroupID);
}

export async function validateSecurity(model) {
    return axiosPost('/Affiliate/ValidateSecurityAnswer',model);
}

export async function GetSecretQuestionByAffiliateCode(affiliateCode) {
    return axiosGet('/Affiliate/GetSecretQuestionByAffiliateCode', {
        affiliateCode: affiliateCode
    });
}

export async function getPasswordResetLink(affiliateCode, tokenId, tokenValue) {
    return axiosGet('/Affiliate/ValidatePasswordResetLink', {
        affiliateCode: affiliateCode,
        tokenId: tokenId,
        tokenValue: tokenValue
    });
}

export async function updateForgotPassword(model, firstPhaseAttempts, tokenId, validatedAffiliateCode) {
    return axiosPut('/Affiliate/UpdateForgotPassword', model,{
        firstPhaseAttempts : firstPhaseAttempts,
        tokenId : tokenId,
        validatedAffiliateCode : validatedAffiliateCode
    });
}