export const localeArray = [
  'zh-cn',
  'en-cn',
  'th-cn',
  'vi-cn',
  'id-cn',
  'th-th',
  'en-th',
  'zh-th',
  'id-th',
  'vi-th',
  'id-id',
  'en-id',
  'zh-id',
  'th-id',
  'vi-id',
  'vi-vn',
  'en-vn',
  'id-vn',
  'zh-vn',
  'th-vn'
];

/*Trigger Change*/
