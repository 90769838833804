export const otpModule = {
    BankAccount: 'BankAccount',
    Registration: 'Registration',
    ResetPassword: 'ResetPassword',
    UpdatePassword: 'UpdatePassword',
    CashCard: 'CashCard',
    CryptocurrencyWallet: 'CryptocurrencyWallet',
    TransferToMember: 'TransferToMember',
    AffiliateLogin: 'Login',
    CashReceiver: 'CashReceiver'
}