import * as actions from '../../constant/reduxActionTypes';
import { updateObject, performLogout } from '../../utils/common';

const initialState = {
  showPopUp: false,
  component: null,
  param: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_POPUP:
      return updateObject(state, {
        showPopUp: true,
        component: action.componentName,
        param: action.param
      });
    case actions.CLOSE_POPUP:
      return updateObject(state, {
        showPopUp: false,
        component: null,
      });
    case actions.CLOSE_POPUP_LOGOUT:
      return performLogout();
    case actions.CLOSE_POPUP_TRANSFER:
      updateObject(state, {
        showPopUp: false,
        component: null,
      });
      return window.location.reload();
    default:
      return state;
  }
};

export default reducer;
