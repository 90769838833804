import React from 'react';
import { injectIntl } from 'react-intl';
import { getCurrencyCode } from '../utils/formatter';
import { getActivatedComm100 } from '../services/comm100';
import { getAffiliateCustomVariables } from '../services/affiliate';

class GlobalLiveChat extends React.Component {
    componentDidMount() {
        if (localStorage.getItem('app.token') !== null) {
            getAffiliateCustomVariables().then(response => {
                if (response.data.result !== null) {
                    document.getElementById('hidCustomVariables').value = 'AffiliateCode=' + response.data.result.affiliateCode
                        + '&AffiliateUserName=' + response.data.result.userName
                        + '&AffiliateType=' + response.data.result.type
                        + '&CommissionPercentage=' + response.data.result.commissionPercentage
                        + '&EarningModel=' + response.data.result.earningModel
                        + '&WithdrawalStatus=' + response.data.result.withdrawalStatus
                        + '&EarningStatus=' + response.data.result.earningStatus;
                }
            }).catch(error => {
                console.error(error);
            });
        }
        if (['cn', 'th', 'vn'].includes(this.props.intl.locale.split('-')[1])) {
            window.Comm100API = {};
            window.Domain = null;

            getActivatedComm100(getCurrencyCode(this.props.intl.locale)).then(response => {
                if (response.data.result.backUpDomain) {
                    window.popup_chat_window = () => {
                        window.open(response.data.result.backUpDomain + '?planId=' + response.data.result.planCode + '&siteId=' + response.data.result.siteID, "Live Chat", "height=600, width=540,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes,top=0");
                    }
                }
                window.Comm100API = window.Comm100API || {};
                (
                    function (t) {
                        window.Domain = response.data.result.domain + '?siteId=';

                        function e(e) {
                            try {
                                var a = document.createElement("script"),
                                    c = document.getElementsByTagName("script")[0];
                                a.type = "text/javascript";
                                a.async = !0;
                                a.src = e + t.site_id;
                                c.parentNode.insertBefore(a, c);
                            }
                            catch (err) {
                                console.error(err.message);
                                console.error('Unable to prompt live chat box');
                            }
                        }

                        function o() {
                            var i = window.frames['chat_window_container'];
                            i.style.display = 'block';
                            if (i.contentDocument.getElementById('id-ntm') === null) {
                                var css = 'a.chatButton { display: none; }',
                                    d = i.contentDocument.createElement("style"),
                                    f = i.contentDocument.getElementsByTagName("style")[0];
                                d.setAttribute('id', 'id-ntm');
                                d.type = "text/css";
                                if (d.styleSheet) {
                                    d.styleSheet.cssText = css;
                                } else {
                                    d.appendChild(document.createTextNode(css));
                                }
                                f.parentNode.insertBefore(d, f);
                            }

                        }

                        function c() {
                            var i = window.frames['chat_window_container'];
                            i.style.display = 'none';
                        }

                        t.chat_buttons = t.chat_buttons || [];
                        t.chat_buttons.push({ code_plan: response.data.result.planCode, div_id: "comm100-button" });
                        t.site_id = response.data.result.siteID;
                        t.main_code_plan = response.data.result.planCode;
                        e(window.Domain);
                        setTimeout(function () {
                            t.loaded || e(window.Domain);
                            try {
                                if (typeof t.on === 'function') {
                                    t.on('livechat.prechat.display', o);
                                    t.on('livechat.prechat.restore', o);
                                    t.on('livechat.prechat.close', c);
                                    t.on('livechat.prechat.minimize', c);
                                    t.on('livechat.chat.display', o);
                                    t.on('livechat.chat.restore', o);
                                    t.on('livechat.chat.close', c);
                                    t.on('livechat.chat.minimize', c);
                                    t.on('livechat.postchat.display', o);
                                    t.on('livechat.postchat.restore', o);
                                    t.on('livechat.postchat.close', c);
                                    t.on('livechat.postchat.minimize', c);

                                    var customVariables = [];
                                    var inputVal = document.getElementById('hidCustomVariables').value;
                                    if (inputVal) {
                                        var vals = inputVal.split('&');
                                        for (var i = 0; i < vals.length; i++) {
                                            customVariables.push({ name: vals[i].split('=')[0], value: vals[i].split('=')[1] });
                                        }
                                    }
                                    t.set('livechat.customVariables', customVariables);
                                }
                            }
                            catch (err) {
                                console.error(err.message);
                                console.error('Unable to prompt live chat box');
                            }
                        },
                            5e3);
                    })(window.Comm100API || {});

            }).catch(error => {
                console.error(error);
            });
        }
    }

    render() {
        return (
            <React.Fragment />
        );
    }
};

export default injectIntl(GlobalLiveChat);
