import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ErrorMessage from '../components/Error/NotFound';
import '../components/Error/Error.css'; 

class NotFound extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  redirectToHome = () => {
    window.location.href = process.env.REACT_APP_SUB_FOLDER;
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
    const { width } = this.state;
    const isMobile = width <= 850;
    return (
      <ErrorMessage isMobile={isMobile} />
    )
  }
};

export default injectIntl(NotFound);
