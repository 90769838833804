import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
    showReminderPopUp: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.OPEN_REMINDER_POPUP:
            return updateObject(state, {
                showReminderPopUp: true
            });
        case actions.CLOSE_REMINDER_POPUP:
            return updateObject(state, {
                showReminderPopUp: false
            });
        default:
            return state;
    }
};

export default reducer;
