export const RegexPattern = {

    // Registration
    RealName: '^[^\\u0021-\\u002F\\u003A-\\u0040\\u005B-\\u0060\\u007B-\\u007E]{2,50}$',
    Username: '^[a-zA-Z0-9]{5,20}$',
    Password: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6,20}$',
    ContactNo: '^[1-9]+[0-9]*$',
    Email: '^[a-zA-Z0-9][-a-zA-Z0-9._]+@([-a-zA-Z0-9]+\\.)+[a-zA-Z]{2,5}$',
    DecimalAmount: '^[0-9]{1,10}(\\.[0-9]*)?$',
    DecimalAmount2Decimalpoints: '^[0-9]{1,10}(\\.\\d{1,2})?$',
    NumberOnly: '^([0-9]*)$',
    AlphaNumeric: '^[a-zA-Z0-9]*$',
    UnicodeName: '(?!^.*^[\\-]*$.*$)(?!^.*-[\\s]*-.*$)(?!^.*\\s\\s.*$)(?!^.*\\s\\-.*$)(?!^.*\\-\\s.*$)(^[^\\d\\?*.\\?!\\@#\\%\\&\\~`\\$\\^_\\,()\\//\\\/-\\<\\>\\-\\+\\=\\￥\\！\\，\\《\\》\\……\\（\\）\\×\\、\\？\\|\\u00a9\\\u00ae\\\u2000\\\u3300\\\ud83c\\ud000\\udfff\\ud83d\\ud000\\udfff\\ud83e\\ud000\\udfff]*$)',
    UnicodeNameThb: '(^([^0-9\\s\\?*.\\?!\\@#\\%\\&\\~`\\$\\^_\\,()\\//\\\/-\\<\\>\\-\\+\\=\\￥\\！\\，\\《\\》\\……\\（\\）\\×\\、\\？\\|\\u00a9\\\u00ae\\\u2000\\\u3300\\\ud83c\\ud000\\udfff\\ud83d\\ud000\\udfff\\ud83e\\ud000\\udfff]+(\\s)?[^0-9\\s\\?*.\\?!\\@#\\%\\&\\~`\\$\\^_\\,()\\//\\/-\\<\\>\\-\\+\\=\\￥\\！\\，\\《\\》\\……\\（\\）\\×\\、\\？\\|\\u00a9\\\u00ae\\\u2000\\\u3300\\\ud83c\\ud000\\udfff\\ud83d\\ud000\\udfff\\ud83e\\ud000\\udfff]*)$)',
    ConsecutiveSpaces: '[\\s]{2,}',
    Names: '^[^\\u0000-\\u007F]{2,50}$',
    HtmlTag: '<\\/?[\\w\\s="/.\':;#-\\/]+>',
    PhoneNumber:'^([1-9][0-9]*)$',
    ParentAffiliateCode: '^\\d{6}$',
    WalletAddressUSDTERC: '^0x[0-9a-fA-F]{40}$',
    WalletAddressUSDTTRC: '^[Tt][0-9a-zA-Z]{33}$',
    IP: '^([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3})$',
    NotEqualToZero: '(?:0?[1-9]\\d*(?:\\.\\d*[0-9]*)?)|(:?^0.[1-9]+0?)$',
    SecretAnswer: '^[^!@#$%^<>]+$',
    AlphaOnly: '^[A-Za-z]+$',
    CountryValidation: /^(?!^.*^[\-]*$.*$)(?!^.*-[\s]*-.*$)(?!^.*\s\s.*$)(?!^.*\s\-.*$)(?!^.*\-\s.*$)(^[^\?*.\?!\,;:"'\[\]\{\}=+-<>@#\%\&\~`\$\^_0-9\//]*$)/,
    Website: /^$|^(:?(?:https?:\/\/)?(?:www\.)?)?[-a-z0-9]+\.(?:[a-zA-Z]){2,}$/
};
