import { updateObject } from '../../utils/common';
import * as actions from '../../constant/reduxActionTypes';

const initialState = {
    bankAccountList: null,
    countryList: null,
    isOTPEnabled: null,
    paymentMethodList: null,
    walletList: null,
    cryptocurrencyList: null,
    receiverList: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_BANK_ACCOUNTS:
            return updateObject(state, {
                bankAccountList: action.bankAccountList
            });
        case actions.SET_COUNTRY:
            return updateObject(state, {
                countryList: action.countryList
            });
        case actions.SET_OTP:
            return updateObject(state, {
                isOTPEnabled: action.isOTPEnabled
            });
        case actions.SET_PAYMENT_METHOD:
            return updateObject(state, {
                paymentMethodList: action.paymentMethodList
            });
        case actions.SET_WALLET:
            return updateObject(state, {
                walletList: action.walletList
            });
        case actions.SET_CRYPTOCURRENCY:
            return updateObject(state, {
                cryptocurrencyList: action.cryptocurrencyList
            });
        case actions.SET_CASH_RECIEVERS:
            return updateObject(state, {
                receiverList: action.receiverList
            });
        default:
            return state;
    }
};

export default reducer;