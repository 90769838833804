import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
    promotionList: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PROMOTION_LIST:
            return updateObject(state, {
                promotionList: action.promotionList
            });
        default:
            return state;
    }
};

export default reducer;
