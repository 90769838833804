import moment from 'moment';
import { defineMessages } from 'react-intl';
import { axiosGet } from '../services/service';
import { getLocale } from './locale.js';

export const messages = defineMessages({
    "true": {
        "id": "Common.True",
        "defaultMessage": "Yes"
    },
    "false": {
        "id": "Common.False",
        "defaultMessage": "No"
    },
    "CashCard_status_1": {
        "id": "CashCard.Status.Purchased",
        "defaultMessage": "Purchased"
    },
    "CashCard_status_2": {
        "id": "CashCard.Status.Issued",
        "defaultMessage": "Issued"
    },
    "CashCard_status_3": {
        "id": "CashCard.Status.Used",
        "defaultMessage": "Used"
    },
    "CashCard_status_4": {
        "id": "CashCard.Status.Deactivated",
        "defaultMessage": "Deactivated"
    },
    "PendingWithdrawal_status_1": {
        "id": "Withdrawal.Status.Pending",
        "defaultMessage": "Pending"
    },
    "PendingWithdrawal_paymentMethod_Cryptocurrency": {
        "id": "Withdrawal.PaymentMethod.Cryptocurrency",
        "defaultMessage": "Cryptocurrency"
    },
    "PendingWithdrawal_paymentMethod_LocalBank": {
        "id": "Withdrawal.PaymentMethod.LocalBank",
        "defaultMessage": "Local Bank"
    },
    "WithdrawalHistory_paymentMethod_Cryptocurrency": {
        "id": "Withdrawal.PaymentMethod.Cryptocurrency",
        "defaultMessage": "Cryptocurrency"
    },
    "WithdrawalHistory_paymentMethod_LocalBank": {
        "id": "Withdrawal.PaymentMethod.LocalBank",
        "defaultMessage": "Local Bank"
    },
    "WithdrawalHistory_status_1": {
        "id": "Withdrawal.Status.Pending",
        "defaultMessage": "Pending"
    },
    "WithdrawalHistory_status_2": {
        "id": "Withdrawal.Status.Processing",
        "defaultMessage": "Processing"
    },
    "WithdrawalHistory_status_3": {
        "id": "Withdrawal.Status.Approved",
        "defaultMessage": "Approved"
    },
    "WithdrawalHistory_status_4": {
        "id": "Withdrawal.Status.Rejected",
        "defaultMessage": "Rejected"
    },
    "WithdrawalHistory_status_5": {
        "id": "Withdrawal.Status.Cancelled",
        "defaultMessage": "Cancelled"
    },
    "WithdrawalHistory_status_6": {
        "id": "Withdrawal.Status.Reviewed",
        "defaultMessage": "Reviewed"
    },
    "WithdrawalHistory_status_7": {
        "id": "Withdrawal.Status.ComplianceChecking",
        "defaultMessage": "Compliance Checking"
    },
    "WithdrawalHistory_status_11": {
        "id": "Withdrawal.Status.Processing",
        "defaultMessage": "Processing"
    },
    "WithdrawalHistory_status_12": {
        "id": "Withdrawal.Status.Processing",
        "defaultMessage": "Processing"
    },
    'subAffiliate_status_0': {
        "id": "Withdrawal.Status.Pending",
        "defaultMessage": "Pending"
    },
    'subAffiliate_status_1': {
        "id": "Withdrawal.Status.Approved",
        "defaultMessage": "Approved"
    },
    'subAffiliate_status_2': {
        "id": "Withdrawal.Status.Rejected",
        "defaultMessage": "Rejected"
    },
    "TransactionHistory_status_Withdrawal_1": {
        "id": "Withdrawal.Status.Pending",
        "defaultMessage": "Pending"
    },
    "TransactionHistory_status_Withdrawal_2": {
        "id": "Withdrawal.Status.Processing",
        "defaultMessage": "Processing"
    },
    "TransactionHistory_status_Withdrawal_3": {
        "id": "Withdrawal.Status.Approved",
        "defaultMessage": "Approved"
    },
    "TransactionHistory_status_Withdrawal_4": {
        "id": "Withdrawal.Status.Rejected",
        "defaultMessage": "Rejected"
    },
    "TransactionHistory_status_Withdrawal_5": {
        "id": "Withdrawal.Status.Cancelled",
        "defaultMessage": "Cancelled"
    },
    "TransactionHistory_status_Withdrawal_6": {
        "id": "Withdrawal.Status.Reviewed",
        "defaultMessage": "Reviewed"
    },
    "TransactionHistory_status_Withdrawal_7": {
        "id": "Withdrawal.Status.ComplianceChecking",
        "defaultMessage": "Compliance Checking"
    },
    "TransactionHistory_status_Withdrawal_11": {
        "id": "Withdrawal.Status.Processing",
        "defaultMessage": "Processing"
    },
    "TransactionHistory_status_Withdrawal_12": {
        "id": "Withdrawal.Status.Processing",
        "defaultMessage": "Processing"
    },
    "TransactionHistory_status_Transfer_0": {
        "id": "Transfer.Type.Pending",
        "defaultMessage": "Pending"
    },
    "TransactionHistory_status_Transfer_1": {
        "id": "Transfer.Type.Pending",
        "defaultMessage": "Pending"
    },
    "TransactionHistory_status_Transfer_2": {
        "id": "Transfer.Type.Success",
        "defaultMessage": "Success"
    },
    "TransactionHistory_status_Transfer_3": {
        "id": "Transfer.Type.Failed",
        "defaultMessage": "Failed"
    },
    "TransactionHistory_status_CashCard_1": {
        "id": "CashCard.Status.Purchased",
        "defaultMessage": "Purchased"
    },
    "TransactionHistory_status_CashCard_2": {
        "id": "CashCard.Status.Issued",
        "defaultMessage": "Issued"
    },
    "TransactionHistory_status_CashCard_3": {
        "id": "CashCard.Status.Used",
        "defaultMessage": "Used"
    },
    "TransactionHistory_status_CashCard_4": {
        "id": "CashCard.Status.Deactivated",
        "defaultMessage": "Deactivated"
    },
    "TransactionHistory_status_MonthEarnings_3": {
        "id": "MonthEarnings.Type.Success",
        "defaultMessage": "Success"
    },
    "TransactionHistory_status_Adjustment_2": {
        "id": "Adjustment.Type.Success",
        "defaultMessage": "Success"
    }
})

export const formatCurrency = (locale, amount) => {

    if (amount > 0 || amount < 0) {
        return `${getCurrencySymbol(locale.slice(-2))} ${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    } else {
        return `${getCurrencySymbol(locale.slice(-2))} ${amount + '.00'}`;
    }
}

export const formatMoneyAmount = (amount, locale, key) => {
    // add currency symbol dashboard's this month's overwview currency column
    let currencySymbol = '';
    if (currencyKey.includes(key)) {
        currencySymbol += getCurrencySymbol(locale.slice(-2));
    }

    let formatAmt = 0;
    if (AmountOnlyKey.includes(key)) {
        formatAmt = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else {
        formatAmt = amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }
    return `${currencySymbol + formatAmt}`;
}

export const formatDecimalAmount = (amount) => {
    return `${amount.toFixed(4)}`;
}

export const formatNonMoneyAmountOnMonthOverview = (amount) => {
    // format non-currency amount with thousand saperator

    return `${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export const getCurrencySymbol = (country) => {
    let currencySymbol = '';
    switch (country) {
        case 'cn':
            currencySymbol = '¥';
            break;
        case 'id':
            currencySymbol = 'Rp';
            break;
        case 'th':
            currencySymbol = '฿';
            break;
        case 'vn':
            currencySymbol = '₫';
            break;
        case 'US':
            currencySymbol = '₮';
            break;
        case 'us':
            currencySymbol = '$';
            break;
        case 'eu':
            currencySymbol = '€';
            break;
        case 'in':
            currencySymbol = '₹';
            break;
        case 'gb':
            currencySymbol = '£';
            break;
        case 'hk':
            currencySymbol = 'HK$';
            break;
        case 'my':
            currencySymbol = 'RM';
            break;
        case 'ph':
            currencySymbol = '₱';
            break;
        case 'sg':
            currencySymbol = '$';
            break;
        case 'tw':
            currencySymbol = 'NT$';
            break;
        default:
            currencySymbol = '¥';
            break;
    }
    return currencySymbol;
}

export const getCurrencyCode = (locale) => {
    let currencyCode = '';
    switch (locale.slice(-2)) {
        case 'cn':
            currencyCode = 'CNY';
            break;
        case 'id':
            currencyCode = 'IDR';
            break;
        case 'th':
            currencyCode = 'THB';
            break;
        case 'vn':
            currencyCode = 'VND';
            break;
        default:
            currencyCode = 'CNY';
            break;
        case 'in':
            currencyCode = 'INR';
            break;
    }
    return currencyCode;
}

export const getCountryCodeByCurrency = (currency) => {
    let countryCode = '';
    switch (currency) {
        case 'CNY':
            countryCode = 'cn';
            break;
        case 'INR':
            countryCode = 'in';
            break;
        case 'IDR':
            countryCode = 'id';
            break;
        case 'THB':
            countryCode = 'th';
            break;
        case 'VND':
            countryCode = 'vn';
            break;
        default:
            countryCode = 'cn';
            break;
    }
    return countryCode;
}

export const getCurrency = (locale) => {
    let currencyCode = '';
    switch (locale.slice(-2)) {
        case 'cn':
            currencyCode = 'RMB';
            break;
        case 'id':
            currencyCode = 'IDR';
            break;
        case 'th':
            currencyCode = 'THB';
            break;
        case 'vn':
            currencyCode = 'VND';
            break;
        default:
            currencyCode = 'RMB';
            break;
    }
    return currencyCode;
}

const currencyKey = [
    'GGR',
    'NGR',
    'Marketing Cost',
    'AllProducts',
    'Sportsbook',
    'RNG',
    'Live dealer',
    'Fishing',
    'Keno',
    'Turnover',
    'P2P',
    'Commissions',
    'firstDepositAmount',
    'depositAmount',
    'marketingCost',
    'turnover',
    'totalTurnover',
    'ggr',
    'ngr',
    'subAffiliateEarnings',
    'TotalGGR',
    'TotalGGR DS',
    'TotalCosts DS',
    'TotalNGR DS',
    'Carryover DS',
    'DepositAmount DS',
    'WithdrawalAmount DS',
    'Turnover DS',
    'FirstDepositAmount DS',
    'Sportsbook DS',
    'Casino DS',
    'Keno DS',
    'P2P DS',
    'Slot DS',
    'Fishing Game DS',
    'totalRakeAmount',
    'Deposit',
    'Withdrawal',
    'deposit',
    'withdrawal',
    'totalDeposit',
    'totalWithdrawal',
    'summaryGGR',
    'summaryTurnover',
    'bonusAmount',
    'rebatesAmount',
    'rewardsAmount',

    //incomestatement
    'rakeAmount',
    'bonus',
    'rebates',
    'rewards',
    'adminCost',
    'paymentCost',
    'accumulativeWin',
    'adjustments',
    'totalNGR',
    'memberEarnings',
    'subAffEarnings',
    'subAffEarning',
    'totalEarnings',
    'actualCalc',
    'calculatedAmt',
    'amountFrom',
    'earningAdjustment',

    //cashcard
    'CashCard_amount',

    //pending withdrawal
    'PendingWithdrawal_amount',

    //Transaction History Report
    'TransactionHistory_amount'
]

const percentageKey = [
    'conversion',
    'tierRate',
    'affiliateTreeCommission'
]

const fullAmountKey = [
    'subAffiliateEarnings',
    'Commissions'
]

const AmountOnlyKey = [
    'Qualified Member',
    'Click Count',
    'New Member',
    'First Deposit Count',
    'ClickCount DS',
    'Registration DS',
    'FirstDepositCount DS',
    'DepositCount DS',
    'WithdrawalCount DS',
    'ActiveMember DS',
    'clickCount',
    'firstDepositCount',
    'signUpCount',
    'activeMember'
]

const translationKey = [
    'CashCard_status',
    'PendingWithdrawal_status',
    'WithdrawalHistory_status',
    'subAffiliate_status',
    'PendingWithdrawal_paymentMethod',
    'WithdrawalHistory_paymentMethod',
    'TransactionHistory_status'
]

const columnHeaderKey = [
    'lastLoginAt'
]

const isDateFormat = value => {
    return (value.length >= 10 && value[4] === '-' && value[7] === '-');
}

export const formatValueTransactionDetailsStatus = (value, key, intl, transactionType) => {
    transactionType = transactionType.replace(' ', '');
    if (translationKey.includes(key)) {
        return messages[key + '_' + transactionType + '_' + value] ? intl.formatMessage(messages[key + '_' + transactionType + '_' + value]) : value;
    }
}

export const formatValue = (value, key, intl, isTabLabel) => {
    if (key != '') {
        if (value === '' || value === null || value === undefined) {
            return '-';
        }
        else if (key === 'lastLoginAt') {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
        else if (key === 'PendingWithdrawal_paymentMethod' || key === 'WithdrawalHistory_paymentMethod') {
            //remove white space for withdrawal module
            value = value.replace(/\s+/g, "");
        }
    }

    if (typeof value == 'boolean') {
        return value ? intl.formatMessage(messages['true']) : intl.formatMessage(messages['false'])
    }

    if (translationKey.includes(key)) {
        return messages[key + '_' + value] ? intl.formatMessage(messages[key + '_' + value]) : value;
    }

    if (typeof value !== 'number') {

        if (typeof value === 'string' && isDateFormat(value)) {
            return value.substring(0, 10);
        }
        return value;
    }

    var formattedAmount = value;

    if (fullAmountKey.includes(key) && isTabLabel) {
        formattedAmount = value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //Number formatter
    if (AmountOnlyKey.includes(key) && isTabLabel) {
        formattedAmount = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    else if (percentageKey.includes(key) && key === 'tierRate') {
        formattedAmount = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    else if (key === 'WithdrawalHistory_amount') {
        if (value >= 0) {
            formattedAmount = value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            formattedAmount = getCurrencySymbol(intl.locale.slice(-2)) + ' ' + formattedAmount;
        } else {
            value = -value;
            formattedAmount = value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            formattedAmount = getCurrencySymbol(intl.locale.slice(-2)) + ' ' + formattedAmount;
            formattedAmount = '- ' + formattedAmount;
        }
        return formattedAmount;
    }

    else if (key === 'PendingWithdrawal_convertedAmt' || key === 'WithdrawalHistory_convertedAmt') {
        return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    else if (key === 'PendingWithdrawal_exchangeRate' || key === 'WithdrawalHistory_exchangeRate') {
        return value.toFixed(4);
    }

    // else if ((value >= 1000 || value <= -1000) && isTabLabel) {
    //  formattedAmount = (value / 1000).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + 'k';
    // } 
    else if (currencyKey.includes(key) || percentageKey.includes(key) || key.indexOf('withdrawalAmount') >= 0 || key.indexOf('totalTurnover') > 0 || key.indexOf('Turnover') >= 0 || key.indexOf('GGR') >= 0 || key === 'ReconciliationReport') {
        formattedAmount = value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); //show decimal if currency
    }

    if (currencyKey.includes(key) || key.indexOf('withdrawalAmount') >= 0 || key.indexOf('totalTurnover') > 0 || key.indexOf('GGR') >= 0) {
        formattedAmount = getCurrencySymbol(intl.locale.slice(-2)) + ' ' + formattedAmount;
    } else if (percentageKey.includes(key)) {
        formattedAmount = formattedAmount + '%';
    }

    if (key.indexOf('signUpCount') >= 0 || key.indexOf('firstDepositCount') >= 0 || key.indexOf('qualifiedMember') >= 0 || key.indexOf('totalQualifiedMemberCount') >= 0 || key.indexOf('qualifiedMemberCount') >= 0 || key.indexOf('newMemberCount') >= 0 || key.indexOf('firstDepositCount') >= 0 || key.indexOf('clickCount') >= 0) {

        formattedAmount = formattedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return formattedAmount;
}

export const formatDateTime = (dateTime, value, key) => {
    if (dateTime.substring(0, 10) === '0001-01-01' || dateTime === '') {
        return '-'
    }

    let date = new Date();
    let dateFormat = date.getDate();
    dateFormat = dateFormat < 10 ? '0' + dateFormat : dateFormat;

    let monthFormat = date.getMonth() + 1;
    monthFormat = monthFormat < 10 ? '0' + monthFormat : monthFormat;

    let hours = date.getHours();
    hours = hours < 10 ? '0' + hours : hours;

    let minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;

    let strTime = hours + ':' + minutes;
    return strTime + " " + dateFormat + "/" + monthFormat + "/" + date.getFullYear();
}

export const formatPopupDateTime = (dateTime) => {
    if (dateTime.substring(0, 10) === '0001-01-01' || dateTime === '') {
        return '-'
    }

    let date = new Date(dateTime);
    let dateFormat = date.getDate();
    dateFormat = dateFormat < 10 ? '0' + dateFormat : dateFormat;

    let monthFormat = date.getMonth() + 1;
    monthFormat = monthFormat < 10 ? '0' + monthFormat : monthFormat;

    let yearFormat = date.getFullYear() + "";
    yearFormat = yearFormat.substring(2, 4);

    let hours = date.getHours();
    hours = hours < 10 ? '0' + hours : hours;

    let minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;

    let seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds : seconds;

    let strTime = hours + ':' + minutes + ':' + seconds;
    return yearFormat + "-" + monthFormat + "-" + dateFormat + " " + strTime;
}

export const formatTransferDateTime = (dateTime) => {
    if (dateTime.substring(0, 10) === '0001-01-01' || dateTime === '') {
        return '-'
    }

    let date = new Date(dateTime);
    let dateFormat = date.getDate();
    dateFormat = dateFormat < 10 ? '0' + dateFormat : dateFormat;

    let monthFormat = date.getMonth() + 1;
    monthFormat = monthFormat < 10 ? '0' + monthFormat : monthFormat;

    let yearFormat = date.getFullYear() + "";

    let hours = date.getHours();
    hours = hours < 10 ? '0' + hours : hours;

    let minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;

    let seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds : seconds;

    let strTime = hours + ':' + minutes + ':' + seconds;
    return yearFormat + "-" + monthFormat + "-" + dateFormat + " " + strTime;
}

const contactPrefix = [
    {
        country: 'cn',
        code: '+86'
    },
    {
        country: 'th',
        code: '+66'
    },
    {
        country: 'id',
        code: '+62'
    },
    {
        country: 'vn',
        code: '+84'
    }
]

export const getContactPrefix = (locale) => {
    let prefixCode = contactPrefix.filter(x => x.country === locale.slice(-2));
    if (prefixCode.length > 0) {
        return prefixCode[0].code;
    } else {
        return '+86';
    }
}

export const getMonthYearLocaleFormat = (year, month) => {
    let locale = getLocale().split('-')[0] === 'zh' ? 'zh-cn' : getLocale().split('-')[0];

    moment.locale(locale);
    return moment(year + '-' + month, 'YYYY-M').format('MMMM YYYY');
}

// export const getMonthOnlyLocaleFormat = (year, month) => {
//   return moment(year + '-' + month, 'YYYY-M').month();
// }

export const getMonthOnlyLocaleFormat = (date) => {
    return moment(date, 'YYYY-MM-DD').format('MMM');
}

export const getSettlementDateRange = (date, dateType) => {
    return axiosGet('/Common/SettlementDateRange', {
        date: date,
        type: dateType
    });
}

export const isMarketClosingDate = () => {
    let currentDate = moment().format("YYYY-MM-DD");
    var F1M4_ClosingDate = process.env.REACT_APP_F1M4_CLOSING_DATE;
    return currentDate >= F1M4_ClosingDate;
}
