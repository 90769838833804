import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { messages } from '../Transfer/Translate';
import { Button, Form, Spin } from 'antd';
import * as actions from '../../store/actions';
import MemberUsername from '../Transfer/MemberUsername';
import MemberCode from '../Transfer/MemberCode';
import MemberRegistrationDate from '../Transfer/MemberRegistrationDate';
import MemberTable from '../Transfer/MemberTable';
import moment from 'moment';
import { getMemberSearchList } from '../../services/transfer';

class MemberSearchPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            memberCode: '',
            startDate: moment('1753-01-01').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            memberList: null,
            selectedMember: '',
            isSpinning: false
        }
    }

    translate = (key, param) => {
        return messages[key] ? this.props.intl.formatMessage(messages[key], param) : key;
    }

    handleSearch = () => {
        this.setState({ isSpinning: true });
        getMemberSearchList({
            loginName: this.state.username === null ? '' : this.state.username,
            username: this.state.memberCode === null ? '' : this.state.memberCode,
            startDate: this.state.startDate === null ? moment('1753-01-01').format('YYYY-MM-DD') : this.state.startDate + ' 00:00:00',
            endDate: this.state.endDate === null ? '' : this.state.endDate + ' 23:59:59',
            isAll: true
        }).then(response => {
            if (response.data.result) {
                this.setState({
                    memberList: response.data.result.length > 0 ? response.data.result : null,
                    isSpinning: false
                });
            }
        })
    }

    onUsernameUpdate = e =>{
        this.setState({
            username: e.target.value
        });
    }

    onMemberCodeUpdate = e => {
        this.setState({
            memberCode: e.target.value
        });
    }

    onDateUpdate = (startDate, endDate) => {
        this.setState({
            startDate: startDate === '' ? '' : moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD')
        });
    }

    onSelectedMemberUpdate = (selectedUnmaskedMember, selectedMaskedMember) => {        
        this.props.param.memberCallback(selectedUnmaskedMember, selectedMaskedMember);
    }

    render() {
        const formItemLayout = {
            labelCol: { xs: { span: 24 } },
            wrapperCol: { xs: { span: 24 } }
        };

        return (
            <React.Fragment>
                
                <MemberUsername onUsernameUpdate={this.onUsernameUpdate} {...formItemLayout} {...this.props} />
                <MemberCode onMemberCodeUpdate={this.onMemberCodeUpdate} {...formItemLayout} {...this.props} />
                <MemberRegistrationDate onDateUpdate={this.onDateUpdate} {...formItemLayout} {...this.props} />
                <Button onClick={this.handleSearch} type="primary" style={{ width: '25%', 'marginTop': '10px' }}>{this.translate('Search')}</Button>
                <Spin spinning={this.state.isSpinning} size="large">
                    <MemberTable memberList={this.state.memberList} selectedMember={this.onSelectedMemberUpdate} />
                </Spin>
                {this.state.memberList &&
                    <label className="member-table-records">{this.translate('Records')} : {this.state.memberList.length} </label>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        param: state.popUp.param
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closePopUp: () => dispatch(actions.closePopUp())
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Form.create()(MemberSearchPopUp))
);
