import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
    trackerList: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_TRACKER_LIST:
            return updateObject(state, {
                trackerList: action.trackerList
            });
        default:
            return state;
    }
};

export default reducer;
