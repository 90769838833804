import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { injectIntl } from 'react-intl';
import { handleChangeLocale as changeLocale } from '../../utils/locale';
import LanguageDropDown from './LanguageDropDown';

class LanguageOverlay extends Component {
    constructor(props) {
        super(props);
        this.setPopupRef = this.setPopupRef.bind(this);
        this.handleCloseLanguageOverlay = this.handleCloseLanguageOverlay.bind(this);
    }

    setPopupRef(element) {
        this.languagePopupRef = element;
    }

    handleCloseLanguageOverlay = () => {
        // auto close only apply to the language mobile pop up after login.
        if (localStorage.getItem('app.token') !== null) {
            this.props.closeLanguagePopUp();
        }
    }

    handleChangeLanguage = (locale) => {
        changeLocale(locale);
    }

    render() {
        return (
            <React.Fragment>
                <div className={`language-popup-background ${this.props.showLanguagePopUp ? "open" : ""}`} onClick={this.handleCloseLanguageOverlay}>
                    <div className="language-popup" ref={this.setPopupRef}>
                        <div className="language-popup-menu">
                            <LanguageDropDown showLanguageDropDown={true} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        showLanguagePopUp: state.language.showLanguagePopUp,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeLanguagePopUp: () => dispatch(actions.closeLanguagePopUp()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LanguageOverlay)
);