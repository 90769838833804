import React from 'react';
import { messages } from './Translate';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

class Tnc extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isPopup : false,
        }
    }

    componentDidMount(){
        this.setState({
            isPopup:this.props.isPopup
        })
    }
    componentWillUnmount(){ 
        if(this.state.isPopup){
            this.props.openPopUp('Registration',{
                regModel:{
                    fullName:this.props.regModel.fullName,
                    userName:this.props.regModel.userName,
                    mobilenumber:this.props.regModel.mobilenumber,
                    emailaddress:this.props.regModel.emailaddress
              
                    }
            });
        }
    }

    render() {
        const translate = (key) => {
            return messages[key] ? this.props.intl.formatMessage(messages[key]) : key;
        }
        return (
        <React.Fragment>
            <div className='header'>{translate('TNC_Title1')}</div>
            <p>
                {translate('TNC_Content1.1')} 
            </p>

            <div className='header'>{translate('TNC_Title2')}</div>
            <p>
                {translate('TNC_Content2.1')}
            </p>
            <p>
                {translate('TNC_Content2.1.1')}
            </p>
            <p>
                {translate('TNC_Content2.1.2')}
            </p>
            <p>
                {translate('TNC_Content2.1.3')}
            </p>
            <div className='header'>{translate('TNC_Title3')}</div>
            <p>
                {translate('TNC_Content3.1')}
            </p>
            <p>
                {translate('TNC_Content3.1.1')}
            </p>
            <p>
                {translate('TNC_Content3.1.2')}
            </p>
            <p>
                {translate('TNC_Content3.1.3')}
            </p>
            <p>
                {translate('TNC_Content3.1.4')}
            </p>
            <div className='header'>{translate('TNC_Title4')}</div>
            <p>
                {translate('TNC_Content4.1')}
            </p>
            <p>
                {translate('TNC_Content4.2')}
            </p>
            <div className='header'>{translate('TNC_Title5')}</div>
            <p>
                {translate('TNC_Content5.1')}
            </p>
            <p>
                {translate('TNC_Content5.2')}
            </p>
            <div className='header'>{translate('TNC_Title6')}</div>
            <p>
                {translate('TNC_Content6.1')}
            </p>
            <p>
                {translate('TNC_Content6.2')}
            </p>
            <div className='header'>{translate('TNC_Title7')}</div>
            <p>
                {translate('TNC_Content7.1')}
            </p>
            <p>
                {translate('TNC_Content7.2')}
            </p>
            <p>
                {translate('TNC_Content7.3')}
            </p>
            <div className='header'>{translate('TNC_Title8')}</div>
            <p>
                {translate('TNC_Content8.1')}
            </p>
            <p>
                {translate('TNC_Content8.2')}
            </p>
            <p>
                {translate('TNC_Content8.3')}
            </p>
            
            <div className='header'>{translate('TNC_Title9')}</div>
            <p>
                {translate('TNC_Content9.1')}
            </p>
            <p>
                {translate('TNC_Content9.2')}
            </p>
            <p>
                {translate('TNC_Content9.3')}
            </p>
            <p> 
                {translate('TNC_Content9.3.1')}
            </p>
            <p>
                {translate('TNC_Content9.3.2')}
            </p>
            <p>
                {translate('TNC_Content9.3.3')}
            </p>
            <p>
                {translate('TNC_Content9.3.4')}
            </p>
            <p>
                {translate('TNC_Content9.4')}
            </p>
            <p>
                {translate('TNC_Content9.5')}
            </p>
            <div className='header'>{translate('TNC_Title10')}</div>
            <p>
                {translate('TNC_Content10.1')}
            </p>
            <p>
                {translate('TNC_Content10.2')}
            </p>
            <p>
                {translate('TNC_Content10.3')}
            </p>
        </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
      toggleLoading: (isShow) => dispatch(actions.toggleLoading(isShow)),
      openPopUp: (type, param) => dispatch(actions.openPopUp(type, param)),
      closePopUp: () => dispatch(actions.closePopUp()),
    };
  };

export default injectIntl(
    connect(
      null,
      mapDispatchToProps
    )(Tnc));