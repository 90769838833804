import React from 'react';
import { injectIntl } from 'react-intl';
import './Error.css';
import { messages } from './Translate';
import Message from './Message';
import {getImage} from '../../utils/common';

class ErrorMessage extends React.Component {
 
  render() {
    const { formatMessage } = this.props.intl;
   
    const errorType = 'NotFound';
    
    if(this.props.isMobile){
    return(
        
        <div>
        <div className="error_bg_404">
          <section className="error_desc"> 
             <img className="error_logo" src={getImage(formatMessage(messages.LogoPath))} alt=""/>   
          </section> 
          <div>
            <section className="error_contentChar">    
              <img className="error_char" src={getImage('error/NotFound/NotFoundChar-Mobile.png')} alt=""></img>
              <Message errorType={errorType}/> 
            </section> 
           </div>
        </div>
      </div>
      
    );
    }
    else{
    return(
      
      <div>
      <div className="error_bg_404">
        <section className="error_desc">
           <img className="error_logo" src={getImage(formatMessage(messages.LogoPath))} alt=""/>   
           <Message errorType={errorType}/> 
        </section>
          <section className="error_contentChar">    
            <img className="error_char" src={getImage('error/NotFound/NotFoundChar.png')} alt=""></img>
          </section> 
      </div>
    </div>
    
    )
  }
}
}

export default injectIntl(ErrorMessage);