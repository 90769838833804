import { axiosPost } from './service';

export async function postLogin(username, password) {
  return axiosPost('/Affiliate/Login', {
    hostName: window.location.href,
    grantType: "password",
    clientId: process.env.REACT_APP_API_CLIENTID,
    clientSecret: process.env.REACT_APP_API_CLIENTSECRET,
    username: username,
    password: password,
    scope: "Affiliate.BFF.API offline_access",
    appId: "net.funpodium.fun88",
    siteId: 8
  });
}

export async function postLogout() {
  return axiosPost('/Affiliate/Logout', {
    clientId: process.env.REACT_APP_API_CLIENTID,
    clientSecret: process.env.REACT_APP_API_CLIENTSECRET,
    refreshToken: localStorage.getItem('app.token.refresh'),
    accessToken: localStorage.getItem('app.token')
  });
}

export async function postRefresh() {
  return axiosPost('/api/Refresh', {
    grantType: 'refresh_token',
    clientId: process.env.REACT_APP_API_CLIENTID,
    clientSecret: process.env.REACT_APP_API_CLIENTSECRET,
    refreshToken: localStorage.getItem('app.token.refresh')
  });
}
