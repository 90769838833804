import { axiosGet, axiosPost, axiosDownload, axiosPut } from './service';

export async function getLink(textLinkType) {
    return axiosGet('/Affiliate/Links',{
        textLinkType: textLinkType
    });
}

export async function getQRCode(LinkURL) {
    return axiosDownload('/Affiliate/Links/QR', null, { LinkURL: LinkURL }, 'GET');
}

export async function getTrackerList() {
    return axiosGet('/Affiliate/Links/Trackers');
}

export async function addTracker(name, description) {
    return axiosPost('/Affiliate/Links/Trackers/Add', {
        name: name,
        description: description
    });
}

export async function updateTracker(key, name, description) {
    return axiosPut('/Affiliate/Links/Trackers/Update', {
        key: key,
        name: name,
        description: description
    });
}


