import * as actions from '../../constant/reduxActionTypes';

export const setCurrentPeriod = (dateRange) => {
    return dispatch => {
        dispatch({
            type: actions.SET_PERIOD_LIST,
            currentPeriod: dateRange
        });
    }
};
