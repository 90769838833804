import React from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { messages } from './Translate';
import '../Form/Form.css';

class MemberUsername extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    translate = (key) => {
        return messages[key] ? this.props.intl.formatMessage(messages[key]) : key;
    }

    render() {
        const {
            form: { getFieldDecorator }
        } = this.props;

        return (
            <Form.Item
                className='label-wrapper'
                label={<label className='label'>{this.translate('MemberUsername')}</label>}
                colon={false}
                {... this.props.formItemLayout}
            >
                {
                    getFieldDecorator('MemberUsername', {
                        initialValue: this.props.username
                    })(<Input onChange={this.props.onUsernameUpdate} />)
                }
            </Form.Item>
        );
    }
}

export default injectIntl(MemberUsername);