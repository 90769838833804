import * as actions from '../../constant/reduxActionTypes';

export const openLanguagePopUp = () => {
  return {
    type: actions.OPEN_LANGUAGE_POPUP
  };
};

export const closeLanguagePopUp = () => {
  return {
    type: actions.CLOSE_LANGUAGE_POPUP
  };
};