import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AnonymousOnlyRoute = ({ errorUri, toRender, locale, ...rest }) => {
  const isNotAuthenticated = localStorage.getItem('app.token') === null || localStorage.getItem('app.token') === '';
  const isFirstLogin = localStorage.getItem('app.profile.isFirstLogin');
  const isEmptySecretQnA = localStorage.getItem('app.profile.isEmptySecretQnA');
  errorUri = errorUri === undefined ? `/${locale}/error/notfound` : `/${locale}${errorUri}`;
  return (
    <Route
      {...rest}
      render={props => isNotAuthenticated || isFirstLogin || isEmptySecretQnA  === 'true' ? toRender(props) : <Redirect to={errorUri} />
      }
    />
  );
};

const PrivateRoute = ({ errorUri, toRender, locale, ...rest }) => {
  const isAuthenticated = localStorage.getItem('app.token') !== null;
  errorUri = errorUri === undefined ? `/${locale}/error/notfound` : `/${locale}${errorUri}`;
  return (
  <Route
      {...rest}
      render={props => isAuthenticated ? toRender(props) : <Redirect to={errorUri} />}
    />);
};

const IPRestrictionRoute = ({ errorUri, toRender, locale, ...rest }) => {
  const isAuthenticated = localStorage.getItem('app.token') !== null;
  errorUri = errorUri === undefined ? `/${locale}/error/notfound` : `/${locale}${errorUri}`;
  return (
    <Route
      {...rest}
      render={props => isAuthenticated ? toRender(props) : <Redirect to={errorUri} />}
    />
  );
};

export { AnonymousOnlyRoute, PrivateRoute, IPRestrictionRoute };
