import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';

class GATracker extends React.Component {
    trackPage = page => {
        ReactGA.set({
            page
        });
        ReactGA.pageview(page);
    };

    componentDidMount() {
        ReactGA.initialize(process.env.REACT_APP_API_GOOGLE_ANALYTIC, {
            allowLinker: true
        });
        ReactGA.plugin.require('displayfeatures');
        this.trackPage(this.props.location.pathname);
    }

    componentDidUpdate(prevProps) {
        const prevPage = prevProps.location.pathname;
        const nextPage = this.props.location.pathname;
        if (prevPage !== nextPage) {
            this.trackPage(nextPage);
        }
    }

    render() {
        return (
            <React.Fragment />
        );
    }
};

export default withRouter(GATracker);
