import * as actions from '../../constant/reduxActionTypes';

export const openPopUp = (type, param = null) => {
  return {
    type: actions.OPEN_POPUP,
    componentName: type,
    param: param
  };
};

export const closePopUp = () => {
  return {
    type: actions.CLOSE_POPUP
  };
};

export const closePopUpLogout = () => {
  return {
    type: actions.CLOSE_POPUP_LOGOUT
  }
}

export const closePopUpTransfer = () => {
  return {
    type: actions.CLOSE_POPUP_TRANSFER
  }
}
