import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import './LanguageDropDown.css'
import { getImage, deleteCache } from '../../utils/common';
import { Select, Button, Radio } from 'antd';
import { loadSelectedFlag, loadSelectedLang, handleChangeLocale } from '../../utils/locale';
import LocaleList from './Languages.json';
import { messages } from './Translate';
import { isMarketClosingDate } from '../../utils/formatter';

const Option = Select.Option;
const countries = isMarketClosingDate() ?  ['china', 'thailand', 'vietnam'] : ['china', 'thailand', 'indonesia', 'vietnam'];
const languages = isMarketClosingDate() ? [
  {
    label: '中文',
    supportedCountry: countries
  },
  {
    label: 'ภาษาไทย',
    supportedCountry: countries
  },
  {
    label: 'Tiếng Việt',
    supportedCountry: countries
  },
  {
    label: 'English',
    supportedCountry: countries
  }
]: 
[
  {
    label: '中文',
    supportedCountry: countries
  },
  {
    label: 'ภาษาไทย',
    supportedCountry: countries
  },
  {
    label: 'Bahasa Indo',
    supportedCountry: countries
  },
  {
    label: 'Tiếng Việt',
    supportedCountry: countries
  },
  {
    label: 'English',
    supportedCountry: countries
  }
]

class LanguageDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: loadSelectedFlag(),
      selectedLanguage: loadSelectedLang()
    }
  }

  translate = (key) => {
    return messages[key] ? this.props.intl.formatMessage(messages[key]) : key;
  }

  countryOnChange = value => {
    this.setState({
      selectedCountry: value,
      selectedLanguage: languages.find(lang => lang.supportedCountry.includes(value)).label
    });
  }

  languageOnChange = value => {
    this.setState({
      selectedLanguage: value
    });
  }

  languageOnChangeRadio = event => {
    this.setState({
      selectedLanguage: event.target.value
    }, () => this.handleChangeLanguage());
  }

  handleChangeLanguage = () => {
    handleChangeLocale(LocaleList.find(o => o.flag === this.state.selectedCountry && o.language === this.state.selectedLanguage).code);
    deleteCache();
  }

  render() {
    const isAuthenticated = localStorage.getItem('app.token') !== null;
    return (
      <div className='language-wrapper'>
        { isAuthenticated ? this.languagesRadio() : this.countryLanguageDdl() }
      </div>
    );
  }

  countryLanguageDdl () { // render country & language ddl
    return (
      <div>
        <div className='item'>          
          <div className='label'>{this.translate('Country')}</div>
          <Select
            style={{ width: '100%' }}
            dropdownClassName='lang-dropdown'
            value={this.state.selectedCountry}
            onChange={this.countryOnChange}
            disabled={localStorage.getItem('app.token') !== null}
          >
            {
              countries.map(country =>
                <Option key={country} value={country} className='option'>
                  <img className='flag' src={getImage(`${country}.png`)} alt={country} />
                  <div className='value'>{this.translate(country)}</div>
                </Option>
              )
            }
          </Select>
        </div>
        <div className='item'>
          <div className='label'>{this.translate('Language')}</div>
          <Select
            style={{ width: '100%' }}
            dropdownClassName='lang-dropdown'
            value={this.state.selectedLanguage}
            onChange={this.languageOnChange}
          >
            {
              languages.filter(lang => lang.supportedCountry.includes(this.state.selectedCountry)).map(o =>
                <Option key={o.label} value={o.label} className='option'>
                  {o.label}
                </Option>
              )
            }
          </Select>
        </div>
        <div className='item'><Button type='primary' onClick={this.handleChangeLanguage}>{this.translate('Select')}</Button></div>
      </div>
    );
  }

  languagesRadio () { // render language radios
    return (
      <div>
        <div className='item'>
            <Radio.Group 
              style={{ width: '100%' }}
              onChange={this.languageOnChangeRadio}
              value={this.state.selectedLanguage}
              buttonStyle="solid"
              className='lang-radiogroup'
            >
              {
                languages.filter(lang => lang.supportedCountry.includes(this.state.selectedCountry)).map(o =>
                  <Radio.Button key={o.label} value={o.label} >
                    {o.label}
                  </Radio.Button>
                )
              }
            </Radio.Group>
        </div>
      </div>
    );
  }
};

export default injectIntl(
  LanguageDropDown
);