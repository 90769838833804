import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import MainRoutes from './route/MainRoutes';
import * as actions from './store/actions';
import { withRouter } from 'react-router-dom';
import PopUp from './components/PopUp/PopUp';
import TutorialPopUp from './components/PopUp/TutorialPopUp';
import LanguageOverlay from './components/Language/LanguageOverlay';
import Loading from './components/Loading/Loading';
import { Helmet } from 'react-helmet';
import GATracker from './hoc/GATracker';
import GlobalLiveChat from './hoc/GlobalLiveChat';
import { alertError } from './utils/alert';
import { errorMessages } from './constant/ErrorCodeTranslation';
import PIWIKTracker from './hoc/PIWIKTracker';

const MetaTitleDescription = [
  {
    Language: "zh",
    Title: "FUN88乐天堂联盟| 亚洲顶级线上平台",
    Description: "Fun88，12年老品牌，亚洲第一体育品牌，提供专业化、高质量博彩服务，系统稳定，提供各种优惠促销和奖励计划。横跨中国，泰国，越南，印尼主要市场。",
    Keywords: ""
  },
  {
    Language: "th",
    Title: "พันธมิตร Fun88 | หารายได้จากค่าคอมมิชชั่นสูงสุด 50%",
    Description: "พันธมิตร Fun88 เหมาะสำหรับคนที่ต้องการรายได้พิเศษผลตอบแทนสูง คุณจะได้รับเปอร์เซนต์ค่าคอมมิชชั่นจากการแนะนำลูกค้าสูงสุด 50% จากพันธมิตร Fun88",
    Keywords: ""
  },
  {
    Language: "id",
    Title: "CARA CEPAT KAYA DENGAN PROGRAM AFILIASI",
    Description: "Cari lowongan kerja? Saatnya ikutan program afiliasi dengan komisi hingga miliaran. Afiliasi adalah hubungan kerjasama yang strategis antara anda dengan Fun88, anda akan cepat kaya dengan hanya mengajak pemain anda bermain sportsbook, kasino dan slot di Fun88 dan nikmati freebet, rabat dan cashback untuk pemain anda",
    Keywords: ""
  },
  {
    Language: "en",
    Title: "How to Promote Fun88 Website | Betting Affiliate Programs",
    Description: "Join Fun88 Betting Affiliate Programs and start earning money from your websites by promoting Fun88 Sportsbook and Casino",
    Keywords: ""
  },
  {
    Language: "vi",
    Title: "Fun88 Đại Lý Cá Cược Trực Tuyến Uy Tín Hàng Đầu Châu Á",
    Description: "Thu nhập trọn đời không giới hạn cùng Đại Lý Cá Cược Fun88 khi tham gia giới thiệu bạn bè chỉ với vài bước thực hiện đơn giản mọi lúc mọi nơi",
    Keywords: ""
  }
]

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
      metaTitle: '',
      metaDescription: '',
      metaKeywords: '',
    };
  }

  componentDidMount() {
    // handle events => window size change
    const { width } = this.state;
    this.props.setScreenSize(width);
    window.addEventListener('resize', this.handleWindowSizeChange);
    let selectedMeta = MetaTitleDescription.filter(x => x.Language === this.props.intl.locale.slice(0, 2));
    this.setState({
      metaTitle: selectedMeta[0].Title,
      metaDescription: selectedMeta[0].Description,
      metaKeywords: selectedMeta[0].Keywords,
    })
    this.checkAccountIsLocked();
  }

  componentDidUpdate(prevProps) {
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  checkAccountIsLocked = () => {
    const value = localStorage.getItem('app.locked');
    if (value) {
      alertError(this.translateErrorCode('Error'), this.translateErrorCode(value));
      localStorage.removeItem('app.locked');
    }
  }

  translateErrorCode = (key) => {
    return errorMessages[key] ? this.props.intl.formatMessage(errorMessages[key]) : key;
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.props.setScreenSize(window.innerWidth);
  };

  render() {
    return (
      <React.Fragment>
        <GATracker />
        <PIWIKTracker/>
        <GlobalLiveChat/>
        <Loading />
        <MainRoutes location={this.props.location} />
        <PopUp />
        <LanguageOverlay />
        <TutorialPopUp />
        <Helmet>
          <title>{this.state.metaTitle}</title>
          <meta name="description" content={this.state.metaDescription} />
          <meta name="keywords" content={this.state.metaKeywords} />
        </Helmet>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    visible: state.responsiveui.visible,
    mobile: state.responsiveui.mobile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setScreenSize: window_width => dispatch(actions.resizeScreen(window_width)),
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  )
);
