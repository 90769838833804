import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { messages } from './Translate';
import moment from 'moment';
import * as actions from '../../store/actions';
import '../Form/Form.css';
import './Member.css';

class MemberTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 10,
            count: 10
        }
    }

    onTableScroll = (event) => {
        const e = event.nativeEvent;
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight) {
            if (this.props.memberList && (this.state.size < this.props.memberList.length)) {
                this.setState({
                    size: this.state.size + this.state.count
                });
            }
        }
    }

    onRowClick = (selectedUnmaskedMember, selectedMaskedMember) => {      
        this.props.selectedMember(selectedUnmaskedMember,selectedMaskedMember);
        this.props.closePopUp();
    }

    translate = (key) => {
        return messages[key] ? this.props.intl.formatMessage(messages[key]) : key;
    }

    dateFormat(date) {
        return moment(date).format('YYYY/MM/DD HH:mm:ss');
    }

    render() {
        return (
            <div className="member-table-container" onScroll={this.onTableScroll}>
                <div className="member-table-message">
                    {this.props.memberList && this.props.memberList.length > 0 ?
                        this.translate('SelectRecord') : this.translate('NoRecord')}
                </div>
                <table style={{width:'100%',marginTop:'10px'}}>
                <thead className="member-table-header">
                    {this.props.memberList && 
                        <tr>
                            <th>{this.translate('No')}</th>
                            <th>{this.translate('MemberUsername')}</th>
                            <th>{this.translate('MemberCode')}</th>
                            <th>{this.translate('RegistrationDate')}</th>
                        </tr>
                    }
                </thead>

                    <tbody className="member-table-row">
                    {this.props.memberList &&
                        this.props.memberList.slice(0,this.state.size).map((member, index) => <tr onClick={() => this.onRowClick(member.value, member.description)} key={member.value}>
                            <td>{index + 1}</td>
                            <td>{member.memberUsername}</td>
                            <td>{member.description}</td>
                            <td>{this.dateFormat(member.registrationDate)}</td>
                        </tr>)}
                </tbody>
                </table>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closePopUp: () => dispatch(actions.closePopUp()),
    };
};

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(MemberTable)
);