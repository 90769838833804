import * as actions from '../../constant/reduxActionTypes';

export const openReminderPopUp = () => {
    return {
        type: actions.OPEN_REMINDER_POPUP
    };
};

export const closeReminderPopUp = (e) => {
    return {
        type: actions.CLOSE_REMINDER_POPUP
    };
};

