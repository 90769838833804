import {
    defineMessages
} from 'react-intl';

export const messages = defineMessages({
    "ForgotPassword": {
        "id": "PopUp.Title.ForgotPassword",
        "defaultMessage": "Forgot Password"
    },
    "ResetPassword": {
        "id": "PopUp.Title.ResetPassword",
        "defaultMessage": "Reset Password"
    },
    "Registration": {
        "id": "PopUp.Title.Registration",
        "defaultMessage": "Join us and get your Ticket to a Better Life"
    },
    "RegistrationSuccessful": {
        "id": "PopUp.Title.RegistrationSuccessful",
        "defaultMessage": "Registration Successful"
    },
    "ChangePassword": {
        "id": "PopUp.Title.ChangePassword",
        "defaultMessage": "Change Password"
    },
    "EnforceChangePassword": {
        "id": "PopUp.Title.EnforceChangePassword",
        "defaultMessage": "Change Password"
    },
    "Login": {
        "id": "PopUp.Title.Login",
        "defaultMessage": "Login"
    },
    "AddBank/Wallet": {
        "id": "Bank.AddBank/Wallet",
        "defaultMessage": "Add Bank Account / Wallet"
    },
    "EditBank/Wallet": {
        "id": "Bank.EditBank/Wallet",
        "defaultMessage": "Edit Bank Account / Wallet"
    },
    "TutorialTitleStep1": {
        "id": "PopUp.TutorialTitleStep1",
        "defaultMessage": "Understand your <br />commission details"
    },
    "TutorialDescStep1": {
        "id": "PopUp.TutorialDescStep1",
        "defaultMessage": "Go in to bank center, \nclick on income statement and \nyou can view your earning details"
    },
    "TutorialTitleStep2": {
        "id": "PopUp.TutorialTitleStep2",
        "defaultMessage": "Understand your \noverall performance"
    },
    "TutorialDescStep2": {
        "id": "PopUp.TutorialDescStep2",
        "defaultMessage": "Go into report center, 'nclick on any report that you would like to \nunderstand more"
    },
    "TutorialTitleStep3": {
        "id": "PopUp.TutorialTitleStep3",
        "defaultMessage": "Understand your \npromoting tools"
    },
    "TutorialDescStep3": {
        "id": "PopUp.TutorialDescStep3",
        "defaultMessage": "Go in to tools center, \nview and download your preferred banner and \ntracker link to kick start your affiliate business"
    },
    "EditBank": {
        "id": "Bank.EditBank",
        "defaultMessage": "EDIT BANK"
    },
    "AddSubAffiliate": {
        "id": "SubAffiliate.AddSubAffiliate",
        "defaultMessage": "Add Sub Affiliate"
    },
    "EditSubAffiliate": {
        "id": "SubAffiliate.EditSubAffiliate",
        "defaultMessage": "Edit Sub Affiliate"
    },
    "ViewSubAffiliate": {
        "id": "SubAffiliate.ViewSubAffiliate",
        "defaultMessage": "View Sub Affiliate"
    },
    "AddTracker": {
        "id": "Links.AddTracker",
        "defaultMessage": "Add Subtracker Link"
    },
    "EditTracker": {
        "id": "Links.EditTracker",
        "defaultMessage": "Edit Subtracker Link"
    },
    "OTP": {
        "id": "OneTimePin.OTP",
        "defaultMessage": "One-Time Pin (OTP)"
    },
    "CashCardSuccessful": {
        "id": "CashCard.CashCardSuccessfulTitle",
        "defaultMessage": "Purchase Successful"
    },
    "CashCardDetails": {
        "id": "CashCard.CashCardDetails",
        "defaultMessage": "Cash Card Details"
    },
    "WithdrawalRequestSent": {
        "id": "Withdrawal.WithdrawalRequestSent",
        "defaultMessage": "Withdrawal Request Sent"
    },
    "TNC": {
        "id": "TNC",
        "defaultMessage": "Terms and Conditions"
    },
    "Commission_Page_Title": {
        "id": "Commission_Page.Title",
        "defaultMessage": "Commission earning model"
    },
    "FirstLogin": {
        "id": "FirstLogin.Title",
        "defaultMessage": "Update Profile"
    },
    "LinePopUp": {
        "id": "PopUp.LinePopUp",
        "defaultMessage": "Line QR Code"
    },
    "ZaloPopUp": {
        "id": "PopUp.ZaloPopUp",
        "defaultMessage": "Zalo QR Code"
    },
    "PopUpMessage": {
        "id": "WithdrawalTransactionHistory.InfoTitle",
        "defaultMessage": "Info"
    },
    "CannotGetExchangeRate": {
        "id": "PopUp.CannotGetExchangeRate",
        "defaultMessage": "We are facing some technical problem to retrieve the exchange rate. Please try again after some time."
    },
    "UpdateSecurityQnA": {
        "id": "PopUp.UpdateSecurityQnA",
        "defaultMessage": "Security Question"
    },
    "PopUpIDRMarketClosure": {
        "id": "PopUp.PopUpIDRMarketClosure",
        "defaultMessage": "System Upgrade"
    },
    "PopUpIDRMarketClosure_16March": {
        "id": "PopUp.PopUpIDRMarketClosure_16March",
        "defaultMessage": "System Upgrade"
    },
    "IDRMarketClosure_Content_1": {
        "id": "PopUp.IDRMarketClosure_Content_1",
        "defaultMessage": "Dear Affiliate, \n\nWe have system upgraded for you to have a better experience, you may head to FUN88 Upgrade system and register a new account.\n\n Please contact cs if you have any inquiries or concerns"
    },
    "RedirectToUpgradeSite": {
        "id": "PopUp.RedirectToUpgradeSite",
        "defaultMessage": "Go to Upgrade Site"
    },
    "ContactCS": {
        "id": "PopUp.ContactCS",
        "defaultMessage": "Contact CS"
    },
    "IDRMarketClosure_Content_2_1": {
        "id": "PopUp.IDRMarketClosure_Content_2_1",
        "defaultMessage": "Dear Affiliate, \n\nWe have system upgraded succesful, will direct you to FUN88WIN for a new gaming experience within 5 secound. Please contact cs if you have any inquiries or concerns\n\n​"
    },
    "IDRMarketClosure_Content_2_2": {
        "id": "PopUp.IDRMarketClosure_Content_2_2",
        "defaultMessage": "Please "
    },
    "IDRMarketClosure_Content_2_3": {
        "id": "PopUp.IDRMarketClosure_Content_2_3",
        "defaultMessage": " if you have any inquiries or concerns"
    },
    "TransferSuccessful": {
        "id": "Transfer.TransferSuccessfulTitle",
        "defaultMessage": "Transfer Successful"
    },
    "TransferFail": {
        "id": "Transfer.TransferFailedTitle",
        "defaultMessage": "Transfer Failed"
    },
    "TransferPending": {
        "id": "Transfer.TransferPendingTitle",
        "defaultMessage": "Transfer Pending"
    },
    "TransactionHistoryDetails": {
        "id": "TransactionHistory.TransactionHistoryDetailsTitle",
        "defaultMessage": "Transaction History"
    },
    "USDTDisclaimer.Header": {
        "id": "USDTDisclaimer.Header",
        "defaultMessage": "Dear Valued Customer,"
    },
    "USDTDisclaimer.Content1": {
        "id": "USDTDisclaimer.Content1",
        "defaultMessage": "Please make sure that when you make your withdrawal, you should use the hot wallet or we will refuse it."
    },
    "USDTDisclaimer.Content2": {
        "id": "USDTDisclaimer.Content2",
        "defaultMessage": "If the withdraw is not rejected and still used the exchange wallet to withdraw, if the money is stuck in the exchange platform, we won’t accept any responsibility."
    },
    "USDTDisclaimer.Footer": {
        "id": "USDTDisclaimer.Footer",
        "defaultMessage": "Thank you for you kind cooperation."
    },
    "USDT_Disclaimer": {
        "id": "USDT.Disclaimer",
        "defaultMessage": "Disclaimer"
    },
    "Common.OK": {
        "id": "Common.OK",
        "defaultMessage": "OK"
    },
    "Common.Close": {
        "id": "Common.Close",
        "defaultMessage": "Close"
    },
    "Common.ReminderClose": {
        "id": "Common.ReminderClose",
        "defaultMessage": "Close"
    },
    "ReminderPopUp.PaymentCostAnnouncementL1": {
        "id": "ReminderPopUp.PaymentCostAnnouncementL1",
        "defaultMessage": "乐天堂FUN88合营代理部欢迎你的加入！"
    },
    "ReminderPopUp.PaymentCostAnnouncementL2": {
        "id": "ReminderPopUp.PaymentCostAnnouncementL2",
        "defaultMessage": "添加专员了解更多好礼优惠！"
    },
    "ReminderPopUp.PaymentCostAnnouncementL3": {
        "id": "ReminderPopUp.PaymentCostAnnouncementL3",
        "defaultMessage": "请认准联系方式，尤其Skype请勿随意添加"
    },
    "ReminderPopUp.DoNotShowAgain": {
        "id": "ReminderPopUp.DoNotShowAgain",
        "defaultMessage": "Do not show this message again."
    },
    "ReminderPopUp.Announcement": {
        "id": "ReminderPopUp.Announcement",
        "defaultMessage": "Announcement"
    },
    "ReminderPopUp.Greeting": {
        "id": "ReminderPopUp.Greeting",
        "defaultMessage": "Hi"
    },
    "WelcomePopUp.AnnouncementL1": {
        "id": "WelcomePopUp.AnnouncementL1",
        "defaultMessage": "尊敬的乐天堂代理伙伴："
    },
    "WelcomePopUp.AnnouncementL2": {
        "id": "WelcomePopUp.AnnouncementL2",
        "defaultMessage": "全新的代理优惠活动已经上线！只需前往后台的专属优惠页面，点击参加，即可立即获得特别福利。"
    },
    "WelcomePopUp.AnnouncementL3": {
        "id": "WelcomePopUp.AnnouncementL3",
        "defaultMessage": "联系在线代理客服，添加专员了解更多好礼优惠："
    },
    "WelcomePopUp.AnnouncementL4": {
        "id": "WelcomePopUp.AnnouncementL4",
        "defaultMessage": "注意！！！！"
    },
    "WelcomePopUp.AnnouncementL5": {
        "id": "WelcomePopUp.AnnouncementL5",
        "defaultMessage": "''会员App下载''链接已更新，记得使用最新链接！"
    },
    "WelcomePopUp.DoNotShowAgain": {
        "id": "WelcomePopUp.DoNotShowAgain",
        "defaultMessage": "不再显示此消息"
    },
    "WelcomePopUp.Announcement": {
        "id": "WelcomePopUp.Announcement",
        "defaultMessage": "公告"
    },
    "WelcomePopUp.Greeting": {
        "id": "WelcomePopUp.Greeting",
        "defaultMessage": "您好"
    },
    "WelcomePopUp.FooterL1": {
        "id": "WelcomePopUp.FooterL1",
        "defaultMessage": "最诚挚的问候，"
    },
    "WelcomePopUp.FooterL2": {
        "id": "WelcomePopUp.FooterL2",
        "defaultMessage": "乐天堂代理团队"
    }
})