import { getTrackerList } from '../../services/textlink';
import * as actions from '../../constant/reduxActionTypes';

export const getTextLinkTracker = () => {
    return dispatch => {
        getTrackerList().then(response => {
            try {
                if (response.data.result.trackerDetails) {
                    dispatch({
                        type: actions.SET_TRACKER_LIST,
                        trackerList: response.data.result.trackerDetails
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
};
