import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { messages } from './Translate';
import { Button, Form, Col, Row } from 'antd';
import * as actions from '../../store/actions';

const FormItem = Form.Item;

class USDTDisclaimer extends Component {
    translate = (key, param) => {
        return messages[key] ? this.props.intl.formatMessage(messages[key], param) : key;
    }

    render() {
        return (
            <React.Fragment>
                <label>{this.translate('USDTDisclaimer.Header')}</label><br /><br />
                <label>{this.translate('USDTDisclaimer.Content1')}</label><br />
                <label>{this.translate('USDTDisclaimer.Content2')}</label><br /><br />
                <label>{this.translate('USDTDisclaimer.Footer')}</label><br /><br />
                {!this.props.hideFooter &&
                    <Row>
                        <Col>
                            <Button
                                type="primary"
                                size='medium'
                                style={{ width: '100%' }}
                                onClick={
                                    () => {
                                        this.props.closePopUp();
                                    }
                                }
                            >
                                {this.translate('Common.Close')}
                            </Button>
                        </Col>
                    </Row>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        param: state.popUp.param
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closePopUp: () => dispatch(actions.closePopUp())
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(USDTDisclaimer)
);
