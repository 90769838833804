import { getAffiliateBalance } from '../../services/affiliate';
import * as actions from '../../constant/reduxActionTypes';

export const getBalance = () => {
    return dispatch => {
        getAffiliateBalance()
            .then(response => {
                try {
                    dispatch({
                        type: actions.SET_BALANCE,
                        balance: response.data.result.balance
                    });
                }
                catch (e) {
                    console.error(e);
                }
            })
    };
};