import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';


class Loading extends React.Component {
  render() {
    return (
      this.props.showLoading &&
      <div style={{ zIndex: '20000', height: '100%', width: '100%', position: 'fixed', backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}><Icon type="loading" style={{ fontSize: '90px', color: '#27AAE1' }} /></div>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    mobile: state.responsiveui.mobile,
    showLoading: state.loading.showLoading
  };
};

export default connect(
  mapStateToProps,
  null
)(Loading);