import { getAffiliateBank, getCountryList, getPaymentMethodList, getWalletList, getCryptocurrencyList, getCashReceiverList } from '../../services/bank';
import { getOTPSettings } from '../../services/oneTimePin';
import { otpModule } from '../../constant/otpModule';
import * as actions from '../../constant/reduxActionTypes';

export const getBankOTPSettings = () => {
    return dispatch => {
        getOTPSettings(otpModule.BankAccount).then(response => {
            try {
                dispatch({
                    type: actions.SET_OTP,
                    isOTPEnabled: response.data.result
                });
            } catch (e) {
                console.error(e);
            }
        });
    }
}

export const getCountry = () => {
    return dispatch => {
        getCountryList().then(response => {
            try {
                if (response.data.result) {
                    dispatch({
                        type: actions.SET_COUNTRY,
                        countryList: response.data.result
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
}

export const getBankAccounts = () => {
    return dispatch => {
        getAffiliateBank().then(response => {
            try {
                if (response.data.result) {
                    dispatch({
                        type: actions.SET_BANK_ACCOUNTS,
                        bankAccountList: response.data.result
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
}

export const getCashReceivers = () =>{
    return dispatch => {
        getCashReceiverList().then(response => {
            try {
                if (response.data.result) {
                    dispatch({
                        type: actions.SET_CASH_RECIEVERS,
                        receiverList: response.data.result
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
}

export const getPaymentMethod = () => {
    return dispatch => {
        getPaymentMethodList().then(response => {
            try {
                if (response.data.result) {
                    dispatch({
                        type: actions.SET_PAYMENT_METHOD,
                        paymentMethodList: response.data.result
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
}

export const getWallet = () => {
    return dispatch => {
        getWalletList().then(response => {
            try {
                if (response.data.result) {
                    dispatch({
                        type: actions.SET_WALLET,
                        walletList: response.data.result
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
}

export const getCryptocurrency = () => {
    return dispatch => {
        getCryptocurrencyList().then(response => {
            try {
                if (response.data.result) {
                    localStorage.setItem('app.profile.cryptoCurrency', response.data.result[0].id);
                    dispatch({
                        type: actions.SET_CRYPTOCURRENCY,
                        cryptocurrencyList: response.data.result
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
}
