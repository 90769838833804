import {
    defineMessages
} from 'react-intl';

export const messages = defineMessages({
    "TNC_Title1": {
        "id": "TNC.Title1",
        "defaultMessage": "1. Fun88 Affiliate Program Terms and Conditions"
    },
    "TNC_Content1.1": {
        "id": "TNC.Content1.1",
        "defaultMessage": "1.1. These terms are a binding contract between you and Fun88 which govern your participation in the program. By participating in the program, you agree to be bound by the most current version of these terms, which we may amend from time to time. The said terms will be posted on the web site: https://www.Fun88partners.com/terms. It is your responsibility to ensure that you are familiar with these terms, and your continued participation in the program bounded by the updated version of these terms shall constitute your agreement. In the event you do not agree with the updated terms, your only recourse shall be to terminate your participation in the program."
    },
    "TNC_Title2": {
        "id": "TNC.Title2",
        "defaultMessage": "2. Joining Fun88 Affiliate"
    },
    "TNC_Content2.1": {
        "id": "TNC.Content2.1",
        "defaultMessage": "2.1. If you are not already registered as a Fun88 Partners Affiliate and wish to participate in the program, please submit a completed application on the Fun88 Partners Program Portal: www.Fun88partners.com. By submitting an application, you represent and warrant that:"
    },
    "TNC_Content2.1.1": {
        "id": "TNC.Content2.1.1",
        "defaultMessage": "2.1.1. All information provided when registering as a Fun88 Affiliate is correct and up-to-date and you will update it from time to time in order to ensure that all information remains correct;"
    },
    "TNC_Content2.1.2": {
        "id": "TNC.Content2.1.2",
        "defaultMessage": "2.1.2. Only one Affiliate account is permitted per unique identity;"
    },
    "TNC_Content2.1.3": {
        "id": "TNC.Content2.1.3",
        "defaultMessage": "2.1.3. Fun88 has the final right to reject any applications if Fun88's stake is at risk"
    },
    "TNC_Title3": {
        "id": "TNC.Title3",
        "defaultMessage": "3. Affiliate's Obligation"
    },
    "TNC_Content3.1": {
        "id": "TNC.Content3.1",
        "defaultMessage": "3.1. As a condition of your participation in the Affiliate Program, you undertake, warrant and agree that all use of the marketing materials and all activities undertaken in connection with the Affiliate Programme shall be lawful and in strict accordance with these terms and any special terms (as defined below) set out in the Program Portal."
    },
    "TNC_Content3.1.1": {
        "id": "TNC.Content3.1.1",
        "defaultMessage": "3.1.1. You agree to use your best efforts to market and promote the Fun88 sites in a manner consistent with good business ethics and in good faith towards Fun88."
    },
    "TNC_Content3.1.2": {
        "id": "TNC.Content3.1.2",
        "defaultMessage": "3.1.2. You acknowledge that your promotion of the Fun88 sites has the potential to inflict substantial damage to Fun88 and Fun88's reputation and goodwill, and that you shall at all times act in a manner that will not harm the reputation and goodwill of Fun88."
    },
    "TNC_Content3.1.3": {
        "id": "TNC.Content3.1.3",
        "defaultMessage": "3.1.3. Your activities must not involve any marketing or promotional activity which may have the potential to deceive, confuse or mislead users, or which may infringe on any third party rights, including the rights of privacy, publicity, or Intellectual Property rights. You shall ensure that the marketing materials are displayed only in connection with web sites and materials which are lawful, proper, professional and tasteful."
    },
    "TNC_Content3.1.4": {
        "id": "TNC.Content3.1.4",
        "defaultMessage": "3.1.4. Fun88 reserves the right to demand the immediate takedown or modification of any materials that you distribute, or to demand the cessation of any or all marketing activity in connection with the program or Fun88, at any time and in Fun88's sole discretion. You agree that we are entitled to review your affiliate marketing activities from time to time and that we may approve/reject marketing methods and Affiliate sites used by you in our sole discretion. You undertake and agree to provide prompt assistance and full cooperation in connection with any requests made by Fun88 in this regard."
    },
    "TNC_Title4": {
        "id": "TNC.Title4",
        "defaultMessage": "4. Account Security"
    },
    "TNC_Content4.1": {
        "id": "TNC.Content4.1",
        "defaultMessage": "4.1. You are responsible to guard the security of your program username and password, and may not share your login details with any third party. You shall be solely responsible for all activity occurring under your Affiliate account."
    },
    "TNC_Content4.2": {
        "id": "TNC.Content4.2",
        "defaultMessage": "4.2. We may require you from time to time to positively verify your account details in order to receive continuing commissions or to prevent fraudulent activity in connection with your account. This is to protect both you and us from potential illegal or fraudulent activity. This verification process may require the submission of additional personal documentation proving identity, payment and physical address details."
    },
    "TNC_Title5": {
        "id": "TNC.Title5",
        "defaultMessage": "5. Ownership of Member Data"
    },
    "TNC_Content5.1": {
        "id": "TNC.Content5.1",
        "defaultMessage": "5.1. You acknowledge and agree that all information relating to any referred member is the exclusive and sole property of Fun88 and that you shall have no rights therein whatsoever excluding any information that you gather independently, outside of your participation in the program."
    },
    "TNC_Content5.2": {
        "id": "TNC.Content5.2",
        "defaultMessage": "5.2. You acknowledge and agree that any data we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (&quotEEA&quot). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers."
    },
    "TNC_Title6": {
        "id": "TNC.Title6",
        "defaultMessage": "6. Sub-Affiliates"
    },
    "TNC_Content6.1": {
        "id": "TNC.Content6.1",
        "defaultMessage": "6.1. All rights and licenses granted to you hereunder are non-transferable and non-sublicensable, save that you may assign or delegate any of your duties or obligations to a sub-affiliate approved in writing by Fun88 (the “Sub-Affiliates”). Fun88 reserves the right in its sole discretion to require your Sub-Affiliates to join the program as a condition of their undertaking any activities as a Sub-Affiliate."
    },
    "TNC_Content6.2": {
        "id": "TNC.Content6.2",
        "defaultMessage": "6.2. You are responsible for ensuring that all activities of any Sub-Affiliates is in full compliance with these terms, and any duties and obligations applicable to you hereunder shall equally apply to the Sub-Affiliates. Between you and Fun88, you shall be solely responsible for ensuring the full compliance with these terms by Sub-Affiliates, and you shall remain solely responsible for any acts or omissions of your Sub-Affiliates in violation of these terms."
    },
    "TNC_Title7": {
        "id": "TNC.Title7",
        "defaultMessage": "7. Relationship of Parties"
    },
    "TNC_Content7.1": {
        "id": "TNC.Content7.1",
        "defaultMessage": "7.1. You and Fun88 are independent contractors, and nothing in these Terms will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between the parties."
    },
    "TNC_Content7.2": {
        "id": "TNC.Content7.2",
        "defaultMessage": "7.2 You shall not make any claims, representations, or warranties in connection with us and you shall have no authority to, and shall not, bind us to any obligations, unless we agree in writing to be so bound."
    },
    "TNC_Content7.3": {
        "id": "TNC.Content7.3",
        "defaultMessage": "7.3 You agree that you are not, and shall not be treated as, an employee with respect to, as applicable, any Internal Revenue Code, Social Security Act, Federal Unemployment Acts, or other federal, state, or local statute, ordinance, rule, or regulation of any country whatsoever similar in purpose to the aforementioned codes and acts."
    },
    "TNC_Title8": {
        "id": "TNC.Title8",
        "defaultMessage": "8. Non-Solicitation"
    },
    "TNC_Content8.1": {
        "id": "TNC.Content8.1",
        "defaultMessage": "8.1. During your participation in the program, and for a period of 6 months after any termination of such participation, you undertake not, either directly or indirectly, to solicit, or attempt to solicit, divert or hire away any person engaged by Fun88 as an employee, contractor or consultant at the time of solicitation or during the 12 month period preceding the solicitation."
    },
    "TNC_Content8.2": {
        "id": "TNC.Content8.2",
        "defaultMessage": "8.2. Should you have any doubt as to whether an individual is engaged by Fun88, then you must, prior to attempting any solicitation of such individual, to make a written inquiry of Fun88 in this regard. Your failure to confirm the status of any individual prior to a solicitation shall not relieve you from your duties and obligations under this non-solicitation clause."
    },
    "TNC_Content8.3": {
        "id": "TNC.Content8.3",
        "defaultMessage": "8.3. You agree that in the event of a breach of this non-solicitation clause, Fun88 shall suffer substantial and irreparable harm which may not be adequately compensated for by the payment of damages. As a result, Fun88 shall be entitled to seek injunctive relief in any court of competent jurisdiction to enjoin or prevent such solicitation, and that this will not limit any other causes of action or legal redress that may be available to Fun88."
    },
    "TNC_Title9": {
        "id": "TNC.Title9",
        "defaultMessage": "9. Terms and Termination"
    },
    "TNC_Content9.1": {
        "id": "TNC.Content9.1",
        "defaultMessage": "9.1. Either party may terminate these terms and your participation in the program for convenience at any time, effective upon written notice to the other."
    },
    "TNC_Content9.2": {
        "id": "TNC.Content9.2",
        "defaultMessage": "9.2. Any notice of termination shall be given in writing by either party to the other. For purposes of notification of termination, delivery via e-mail is considered a written and immediate form of notification and the terms and your participation in the program shall accordingly terminate with immediate effect."
    },
    "TNC_Content9.3": {
        "id": "TNC.Content9.3",
        "defaultMessage": "9.3. Upon termination of participation, terms below must apply immediately:"
    },
    "TNC_Content9.3.1": {
        "id": "TNC.Content9.3.1",
        "defaultMessage": "9.3.1. All rights and licenses granted to you in these terms shall immediately terminate;"
    },
    "TNC_Content9.3.2": {
        "id": "TNC.Content9.3.2",
        "defaultMessage": "9.3.2. You must immediately cease all marketing activities, cease the distribution of any marketing materials, and disable any links from your Site to any Fun88 site/s;"
    },
    "TNC_Content9.3.3": {
        "id": "TNC.Content9.3.3",
        "defaultMessage": "9.3.3. You must promptly return to us any confidential information and/or member information, and all copies of same in your possession, custody and control; and"
    },
    "TNC_Content9.3.4": {
        "id": "TNC.Content9.3.4",
        "defaultMessage": "9.3.4. or clarification purposes, termination will not exculpate you from any liability arising from any breach of these terms, that occurred prior to termination."
    },
    "TNC_Content9.4": {
        "id": "TNC.Content9.4",
        "defaultMessage": "9.4. Upon termination for reasons other than breach of these terms by you, you shall continue to be entitled for a period of six months to receive commissions on the terms and conditions as specified herein."
    },
    "TNC_Content9.5": {
        "id": "TNC.Content9.5",
        "defaultMessage": "9.5. In the event that we terminate your participation in the program as a result of a breach of these terms by you, you shall not be entitled to receive any additional commissions effective to date of termination. In the event that we terminate your participation in the program as a result of any fraudulent activity or activities which we believe to be unlawful or in bad faith, we reserve the right to recover any payments previously made to you and seek the recovery of all costs incurred in the investigation of such activities and the closure of your account, in addition to any other rights and remedies available at law."
    },
    "TNC_Title10": {
        "id": "TNC.Title10",
        "defaultMessage": "10. Miscellaneous"
    },
    "TNC_Content10.1": {
        "id": "TNC.Content10.1",
        "defaultMessage": "10.1. These terms will be governed by the laws of Malta without reference to rules governing choice of laws. Any action relating to these terms must be brought in Malta and you irrevocably consent to the jurisdiction of these courts."
    },
    "TNC_Content10.2": {
        "id": "TNC.Content10.2",
        "defaultMessage": "10.2. You acknowledge, confirm, and agree that damages may be inadequate for a breach or a threatened breach of these terms and, in the event of a breach or threatened breach of any provision of these terms, the respective rights and obligations of the parties shall be enforceable by specific performance, injunction, or other equitable remedy. Nothing contained in these Terms shall limit or affect any of our rights at law, or otherwise, for a breach or threatened breach of any provision of these terms."
    },
    "TNC_Content10.3": {
        "id": "TNC.Content10.3",
        "defaultMessage": "10.3. Whenever possible, each provision of these terms shall be interpreted in such a manner as to be effective and valid under applicable law but, if any provision of these terms is held to be invalid, illegal or unenforceable in any respect, such provision will be ineffective only to the extent of such invalidity, or unenforceability, without invalidating the remainder of these terms or any provision hereof."
    },
    "Faq_Header1": {
        "id": "Faq_Header1",
        "defaultMessage": "Starting up"
    },
    "Faq_Header2": {
        "id": "Faq_Header2",
        "defaultMessage": "General"
    },
    "Faq_Header3": {
        "id": "Faq_Header3",
        "defaultMessage": "Commissions"
    },
    "Faq_Header4": {
        "id": "Faq_Header4",
        "defaultMessage": "Reporting and statistics"
    },
    "Faq_Header5": {
        "id": "Faq_Header5",
        "defaultMessage": "Support and help"
    },
    "Faq_Title1": {
        "id": "Faq_Title1",
        "defaultMessage": "What is the Fun88.com Affiliate Program?"
    },
    "Faq_Title2": {
        "id": "Faq_Title2",
        "defaultMessage": "How much money can I earn with this program?"
    },
    "Faq_Title3": {
        "id": "Faq_Title3",
        "defaultMessage": "How does the Fun88 Affiliate Program work?"
    },
    "Faq_Title4": {
        "id": "Faq_Title4",
        "defaultMessage": "Will it cost anything to join?"
    },
    "Faq_Title5": {
        "id": "Faq_Title5",
        "defaultMessage": "How often do I get paid?"
    },
    "Faq_Title6": {
        "id": "Faq_Title6",
        "defaultMessage": "How do I know how much money I'm making?"
    },
    "Faq_Title7": {
        "id": "Faq_Title7",
        "defaultMessage": "How can I keep track of my performance?"
    },
    "Faq_Title8": {
        "id": "Faq_Title8",
        "defaultMessage": "How is your commission structured? "
    },
    "Faq_Title9": {
        "id": "Faq_Title9",
        "defaultMessage": "When can I withdraw my commission? "
    },
    "Faq_Title10": {
        "id": "Faq_Title10",
        "defaultMessage": "Do I get a summary report?"
    },
    "Faq_Title11": {
        "id": "Faq_Title11",
        "defaultMessage": "Where do I find my statistics?"
    },
    "Faq_Title12": {
        "id": "Faq_Title12",
        "defaultMessage": "How do I update my personal account information?"
    },
    "Faq_Title13": {
        "id": "Faq_Title13",
        "defaultMessage": "I am not sure my link/tag is working properly. How do I know that my referrals are being tracked to me?"
    },
    "Faq_Title14": {
        "id": "Faq_Title14",
        "defaultMessage": "Comments, Suggestions, Complaints"
    },
    "Faq_Content1_1": {
        "id": "Faq_Content1_1",
        "defaultMessage": "The Fun88 Affiliate Program is a partnership with a merchant who pays you a percentage of the revenues generated by clients/players that you have introduced to them. An Affiliate Program is for this reason an easy way to generate extra profits from the traffic to your website, and in return are credited a monthly commission payment based on net wagering profits made by the casino from your players. "
    },
    "Faq_Content2_1": {
        "id": "Faq_Content2_1",
        "defaultMessage": "The amount of money you can earn is truly up to you. There is literally no limit to your earnings. The general rule is the more time and effort you put in to the program, the more you will get out of it. When you sign up as our affiliate, you will be provided with all the tools necessary to transform your website into a powerful money-making machine. "
    },
    "Faq_Content3_1": {
        "id": "Faq_Content3_1",
        "defaultMessage": "When a visitor to your site clicks a link, banner or any other type of marketing tool that we provide for you, that visitor will go to our site. If the visitor registers and starts playing, then you will earn money from that player for as long as he/she plays at the site. Every time the player makes a deposit and play you are making money from their losses. Also note that your earning potential is not limited to referring players - you can also earn cash by referring other affiliates!"
    },
    "Faq_Content4_1": {
        "id": "Faq_Content4_1",
        "defaultMessage": "It won't cost you a cent to join! To be our affiliate is absolutely free. There isn't really a catch at all. Simply click the ‘join now’ link, sign up to get your unique affiliate ID and start marketing our clients to your traffic."
    },
    "Faq_Content5_1": {
        "id": "Faq_Content5_1",
        "defaultMessage": "We process all Affiliate payments by the 10th working day of each month! "
    },
    "Faq_Content6_1": {
        "id": "Faq_Content6_1",
        "defaultMessage": "Your unique affiliate code is inserted into every link you generate on Fun88 which points to any of our players. This way every banner, text link or acquisition email you send links your players to your unique Fun88 Affiliate Account. As these players spend money you have access to a number of statistical and financial reports on Fun88 which will allow you to track the daily movements of your players across all our clients in one place."
    },
    "Faq_Content7_1": {
        "id": "Faq_Content7_1",
        "defaultMessage": "We provide a centralized system to evaluate and optimize your performance with a minimum effort. All you have to do is go to your reports when you are logged in to My Account and view your earnings from the corresponding partner."
    },
    "Faq_Content8_1": {
        "id": "Faq_Content8_1",
        "defaultMessage": "Earn at least 25% commission on the net losses of your referred players for the lifetime of their membership."
    },
    "Faq_Content9_1": {
        "id": "Faq_Content9_1",
        "defaultMessage": "Affiliates who join our program before the 15th day of the month can withdraw commissions earned this month on the 10th day of next month. And affiliates joining our program after (include) the 15th day, commission earned will be calculated with the following month, and can be withdraw at the 10th day of the third month."
    },
    "Faq_Content9_1_1": {
        "id": "Faq_Content9_1_1",
        "defaultMessage": "For example, if you join us on August 16, you can only withdraw your commission earned in August accumulated with September on October 10."
    },
    "Faq_Content9_1_2": {
        "id": "Faq_Content9_1_2",
        "defaultMessage": "\nPlease note: Your minimum withdrawal must be RMB 1000 or THB 2,000 or VND 1,500 or IDR 1,000 every time."
    },
    "Faq_Content9_1_CN": {
        "id": "Faq_Content9_1_CN",
        "defaultMessage": "The commission paying date will be different by Affiliate Settlement Groups. Each Settlement Group A, B, C will posses the different anticipated paying date respectively as 10th, 20th, 30th every month. The withdrawal is possible after earnings gets confirmed to Main Account balance\nNote: The minimum withdrawal amount will be CNY 1,000 each time"
    },
    "Faq_Content9_1_TH": {
        "id": "Faq_Content9_1_TH",
        "defaultMessage": "The commission paying date will be different by Affiliate Settlement Groups. Each Settlement Group A, B, C will posses the different anticipated paying date respectively as 10th, 20th, 30th every month. The withdrawal is possible after earnings gets confirmed to Main Account balance\nNote: The minimum withdrawal amount will be THB 2,000 each time"
    },
    "Faq_Content9_1_VN": {
        "id": "Faq_Content9_1_VN",
        "defaultMessage": "The commission paying date will be different by Affiliate Settlement Groups. Each Settlement Group A, B, C will posses the different anticipated paying date respectively as 10th, 20th, 30th every month. The withdrawal is possible after earnings gets confirmed to Main Account balance\nNote: The minimum withdrawal amount will be VND 2,000 each time"
    },
    "Faq_Content10_1": {
        "id": "Faq_Content10_1",
        "defaultMessage": "Every month we create a summary report that you will find in My Account under reports."
    },
    "Faq_Content11_1": {
        "id": "Faq_Content11_1",
        "defaultMessage": "Once you have created your Account and log in, you will find your reports and statistics in the left menu in My Account."
    },
    "Faq_Content12_1": {
        "id": "Faq_Content12_1",
        "defaultMessage": "To update your personal information, please log in to your affiliate account and click on the ‘Settings – Personal Information’ tab."
    },
    "Faq_Content13_1": {
        "id": "Faq_Content13_1",
        "defaultMessage": "Please send us your site's full URL and the exact location of our links. We will review them for technical failures."
    },
    "Faq_Content14_1": {
        "id": "Faq_Content14_1",
        "defaultMessage": "Fun88 is very concerned with providing the best possible customer service to every affiliate and referral. We know the only way to run a successful affiliate program is to build positive relationships with each affiliate based on integrity, accuracy, prompt payments and immediate answers to questions. We encourage all affiliates to contact us at any time with any concerns, questions or suggestions. You can find various ways to contact us here."
    },
    "Skype_Thai": {
        "id": "Skype_Thai",
        "defaultMessage": "thai.affiliate_1"
    },
    "Skype_CN": {
        "id": "Skype_CN",
        "defaultMessage": "live:.cid.ea67bbd313a2eb89"
    },
    "Skype_IDR": {
        "id": "Skype_IDR",
        "defaultMessage": "indo.affiliate@yahoo.com"
    },
    "Skype_VN": {
        "id": "Skype_VN",
        "defaultMessage": "viet.affiliate@fun88.com"
    },
    "Email_CN": {
        "id": "Email_CN",
        "defaultMessage": "Daili@fun88.com"
    },
    "Email_Thai": {
        "id": "Email_Thai",
        "defaultMessage": "thai.affiliate@fun88.com"
    },
    "Email_IDR": {
        "id": "Email_IDR",
        "defaultMessage": "indo.affiliate@fun88.com"
    },
    "Email_VN": {
        "id": "Email_VN",
        "defaultMessage": "viet.affiliate@fun88.com"
    },
    "Line_Thai1": {
        "id": "Line_Thai1",
        "defaultMessage": "@fun88aff.th"
    },
    "Line_Thai2": {
        "id": "Line_Thai",
        "defaultMessage": "@supportaff.fun88"
    },
    "Line_IDR": {
        "id": "Line_IDR",
        "defaultMessage": "aldofun88"
    },
    "FB_Thai": {
        "id": "FB_Thai",
        "defaultMessage": "FUN88 Affiliate"
    },
    "Whatsapp_IDR": {
        "id": "Whatsapp_IDR",
        "defaultMessage": "639777614997"
    },
    "Telegram_IDR": {
        "id": "Telegram_IDR",
        "defaultMessage": "AffiliasiIDR Fun88"
    },
    "Telegram_CN": {
        "id": "Telegram_CN",
        "defaultMessage": "@fun88088"
    },
    "Flygram_CN": {
        "id": "Flygram_CN",
        "defaultMessage": "FUN8888"
    },
    "Batchat_CN": {
        "id": "Batchat_CN",
        "defaultMessage": "7125750"
    },
    "Wechat_IDR": {
        "id": "Wechat_IDR",
        "defaultMessage": "aldo_affiliasifun88"
    },
    "Zalo_VN": {
        "id": "Zalo_VN",
        "defaultMessage": "+639567627121"
    },
    "lblSkype": {
        "id": "lblSkype",
        "defaultMessage": "Skype"
    },
    "lblEmail": {
        "id": "lblEmail",
        "defaultMessage": "Email"
    },
    "lblFlygram": {
        "id": "lblFlygram",
        "defaultMessage": "Flygram"
    },
    "lblBatchat": {
        "id": "lblBatchat",
        "defaultMessage": "Batchat"
    },
    "lblLine": {
        "id": "lblLine",
        "defaultMessage": "Line"
    },
    "lblFacebook": {
        "id": "lblFacebook",
        "defaultMessage": "Facebook"
    },
    "lblWhatsapp": {
        "id": "lblWhatsapp",
        "defaultMessage": "Whatsapp"
    },
    "lblTelegram": {
        "id": "lblTelegram",
        "defaultMessage": "Telegram"
    },
    "lblLetstalk": {
        "id": "lblLetstalk",
        "defaultMessage": "Letstalk"
    },
    "lblWechat": {
        "id": "lblWechat",
        "defaultMessage": "Wechat"
    },
    "lblZalo": {
        "id": "lblZalo",
        "defaultMessage": "Zalo"
    },
    "lblACS": {
        "id": "lblACS",
        "defaultMessage": "Affiliate Customer Service"
    },
    "lblLiveChat": {
        "id": "Sidebar.LiveChat",
        "defaultMessage": "Live Chat"
    },
    "ContactUs_Header_CN": {
        "id": "ContactUs_Header_CN",
        "defaultMessage": "For any questions and suggestions will be answered and taken seriously. We promise to reply you within 24 hours."
    },
    "ContactUs_Header_ID": {
        "id": "ContactUs_Header_ID",
        "defaultMessage": "Our customer service are always available to answer all your questions, payment or anything in native language. " +
            "Please contact our customer service department via the contact channel listed below. \nOur team is ready to serve you from 10am to 7pm, " +
            "from Monday to Saturday. Please leave us a message, we will reply you within 24 hours."
    },
    "ContactUs_Header_TH": {
        "id": "ContactUs_Header_TH",
        "defaultMessage": "Affiliate Service Center"
    },
    "ContactUs_Header_VN": {
        "id": "ContactUs_Header_VN",
        "defaultMessage": "FUN88 Customer Service Team is always unhesitant to assist you most efficiently. " +
            "\nPlease contact our Affiliate Support division via the contact channels below. " +
            "\nAside from the mentioned time, all your messages will be responded to within 24 hours from Monday to Sunday."
    },
    "ContactUs_desc": {
        "id": "ContactUs_desc",
        "defaultMessage": "Let us know if you have any questions by simply contacting us with the information below."
    },
    "ContactInfo": {
        "id": "ContactInfo",
        "defaultMessage": "Contact Info"
    },
    "TNC": {
        "id": "TNC",
        "defaultMessage": "Terms and Conditions"
    },
    "FAQ": {
        "id": "FAQ",
        "defaultMessage": "FAQs"
    },
    "operationHourCN1": {
        "id": "operationHourCN1",
        "defaultMessage": "Basic Support \n(Account Registration, Commission Policies, Promotions, Commissions ..., 9am - 2am GMT+8, Everyday)"
    },
    "operationHourCN2": {
        "id": "operationHourCN2",
        "defaultMessage": "Special Support \n(Business, Promotion, Support, Join-Us..., 10am-7pm GMT+8, Monday to Friday)"
    },
    "operationHourTH1": {
        "id": "operationHourTH1",
        "defaultMessage": "For affiliates marketing and information Support, \nService Time  (08:00 - 01:00 GMT+7, everyday)"
    },
    "operationHourTH2": {
        "id": "operationHourTH2",
        "defaultMessage": "For affiliates Special Support, \nService Time(10:00 - 19:00 GMT+7, Monday - Friday)"
    },
    "operationHourVN1": {
        "id": "operationHourVN1",
        "defaultMessage": "Fundamental Support \n(Overall about Affiliate, account registration, commission policies, promotions ...): 11:00 - 23:00 GMT +8, Monday - Sunday"
    },
    "operationHourVN2": {
        "id": "operationHourVN2",
        "defaultMessage": "Special Support \n(10:00 - 19:00 GMT + 8, Monday - Friday)"
    },
    "LineQR1THPopUpDescription": {
        "id": "LineQR1THPopUpDescription",
        "defaultMessage": "For affiliates marketing Support"
    },
    "LineQR2THPopUpDescription": {
        "id": "LineQ2THPopUpDescription",
        "defaultMessage": "For affiliates information Support"
    },
    "lblQQ": {
        "id": "lblQQ",
        "defaultMessage": "QQ"
    },
    "QQ_CN": {
        "id": "QQ_CN",
        "defaultMessage": "3007316094"
    },

})