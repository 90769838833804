import { getMemberSiteRedirect } from '../../services/promotion';
import * as actions from '../../constant/reduxActionTypes';

export const getMemberSiteLink = () => {
    return dispatch => {
        getMemberSiteRedirect().then(response => {
            try {
                if (response.data.result) {
                    dispatch({
                        type: actions.GET_MEMBER_SITE_LINK,
                        memberSiteRedirect: response.data.result.siteURL
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
};