//responsiveui.js
export const SETSCREEN_SIZE = 'SETSCREEN_SIZE';

//loading.js
export const SHOW_LOADING = 'SHOW_LOADING';

//popUp.js
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const CLOSE_POPUP_LOGOUT = 'CLOSE_POPUP_LOGOUT';
export const CLOSE_POPUP_TRANSFER = 'CLOSE_POPUP_TRANSFER';

//language.js
export const OPEN_LANGUAGE_POPUP = 'OPEN_LANGUAGE_POPUP';
export const CLOSE_LANGUAGE_POPUP = 'CLOSE_LANGUAGE_POPUP';

//tutorialPopUp.js
export const OPEN_TUTORIAL_POPUP = 'OPEN_TUTORIAL_POPUP';
export const CLOSE_TUTORIAL_POPUP = 'CLOSE_TUTORIAL_POPUP';

//bank.js
export const SET_OTP = 'SET_OTP';
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_WALLET = 'SET_WALLET';
export const SET_CRYPTOCURRENCY = 'SET_CRYPTOCURRENCY';
export const SET_CASH_RECIEVERS = 'SET_CASH_RECIEVERS';

//account.js
export const SET_BALANCE = 'SET_BALANCE';

//textlink.js
export const SET_TRACKER_LIST = 'SET_TRACKER_LIST';

//promotion.js
export const SET_PROMOTION_LIST = 'SET_PROMOTION_LIST';
export const GET_MEMBER_SITE_LINK = 'GET_MEMBER_SITE_LINK';

export const SET_PERIOD_LIST = 'SET_PERIOD_LIST';

//welcomePopUp.js
export const OPEN_WELCOME_POPUP = 'OPEN_WELCOME_POPUP';
export const CLOSE_WELCOME_POPUP = 'CLOSE_WELCOME_POPUP';

//reminderPopUp.js
export const OPEN_REMINDER_POPUP = 'OPEN_REMINDER_POPUP';
export const CLOSE_REMINDER_POPUP = 'CLOSE_REMINDER_POPUP';