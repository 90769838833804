import * as actions from '../../constant/reduxActionTypes';

export const openTutorialPopUp = (type) => {
  return {
    type: actions.OPEN_TUTORIAL_POPUP,
    tutorialType: type
  };
};

export const closeTutorialPopUp = (e) => {
  return {
    type: actions.CLOSE_TUTORIAL_POPUP
  };
};
