import { getLocale } from './locale';
import { removeLocalStorage } from './localStorage';
import { postLogout } from '../services/authentication';
import { isIOS } from 'react-device-detect';

export function getImage(path) {
    try {
        return require('../assets/img/' + path);
    } catch (e) {
        return '';
    }
}

/**
 * check is empty object/ Array / String
 * Does not check number == 0
 * @param {*} obj
 */
export function isEmptyObject(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

/**
 * polyfill for window.location.origin
 */
export function getWindowLocationOrigin() {
    if (!window.location.origin) {
        window.location.origin =
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '');
    }
    return window.location.origin;
}

export const updateObject = (sourceObject, updateValues) => {
    return {
        ...sourceObject,
        ...updateValues
    };
};

export const handleDownloadObject = (content, fileName) => {
    if (isIOS) {
        let blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        blobtoDataURL(blob, function (dataURL) {
            if ("download" in document.createElement("a")) {
                // non IE
                var elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = dataURL;
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); //remove url target
                document.body.removeChild(elink);
            }
        })
    }
    else {
        let blob = new Blob([content]);

        if ("download" in document.createElement("a")) {
            // non IE
            var elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); //remove url target
            document.body.removeChild(elink);
        } else {
            // IE10+
            navigator.msSaveBlob(blob, fileName);
        }
    }
}

export const blobtoDataURL = (blob, callback) => {
    var reader = new FileReader();
    reader.onload = function (e) {
        callback(e.target.result);
    }
    reader.readAsDataURL(blob);
}

export const getIovation = () => {
    try {
        return window.ioGetBlackbox().blackbox;
    } catch (e) {
        return '';
    }
};

export const getE2 = () => {
    try {
        return window.E2GetBlackbox().blackbox;
    } catch (e) {
        return '';
    }
};

export const openLiveChat = () => {
    if (window.Comm100API && typeof window.Comm100API.open_chat_window === 'function') {
        window.Comm100API.open_chat_window();
    } else if (typeof window.popup_chat_window === 'function') {
        window.popup_chat_window();
    }
}

export const redirectPage = (page) => {

    var destinationPage = page;
    //var destinationPage = "/" + getLocale() + "/" + page;
    return destinationPage;
}

export const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const performLogout = () => {
    postLogout()
        .then(response => {
            if (response.status === 200) {
                removeLocalStorage();

                // Remove Cache when Logout
                // try
                // {
                //     caches.keys().then(function(names){
                //     names = "revamp-pwa";
                //     caches.delete(names);
                //     });
                // }catch{
                //     console.log('No cache');
                // } 

                window.location.href = process.env.REACT_APP_SUB_FOLDER + getLocale()
            }
        })
        .catch(error => {
            console.error(error);
        })
    return "";
}

export async function deleteCache() {
    try {
        // Get a list of all of the caches for this origin
        let cacheNames = await caches.keys();

        for (let name of cacheNames) {
            console.log(name);
            if (name.indexOf("runtime") == 0) {
                await caches.delete(name);
            }
        }
        return;

    } catch (e) {
        return;
    }
}