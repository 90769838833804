import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
    memberSiteRedirect: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_MEMBER_SITE_LINK:
            return updateObject(state, {
                memberSiteRedirect: action.memberSiteRedirect
            });
        default:
            return state;
    }
};

export default reducer;