import React from 'react';
import ReactPiwik from 'react-piwik';
import { withRouter } from 'react-router-dom';

class PIWIKTracker extends React.Component {
    
    constructor(props) { 
        super(props);
        var piwikCode = this.getPiwikCode();
        this.state = {
            piwik:  new ReactPiwik({
                url: '//analytics.ravelz.com/containers/',
                siteId: piwikCode,
                jsFilename: piwikCode +'.js'
              })
        }
    }

    trackPage = location => {
        var code = this.getPiwikCode();
        this.state.piwik["siteId"] = code;
        this.state.piwik["jsFilename"] = code+".js";
        this.state.piwik.track(location);
    };

    getPiwikCode(){
        var ind = parseInt(process.env.REACT_APP_LOCALE_INDEX);
        var locale = window.location.pathname.split('/')[ind];
        var piwikCode = process.env.REACT_APP_API_PIWIK_CODE_M1;
        switch(locale.slice(-2)){
            case "cn": 
                piwikCode = process.env.REACT_APP_API_PIWIK_CODE_M1;
                break;
            case "th":
                piwikCode = process.env.REACT_APP_API_PIWIK_CODE_M2;
                break;
            case "vn":
                piwikCode = process.env.REACT_APP_API_PIWIK_CODE_M3;
                break;
        }
        return piwikCode;
    }

    componentDidUpdate(prevProps) {
        const prevPage = prevProps.location;
        const nextPage = this.props.location;
        if (prevPage.pathname !== nextPage.pathname) 
            this.trackPage(nextPage);
    }

    render() {
        return (
            <React.Fragment />
        );
    }
};

export default withRouter(PIWIKTracker);
