import * as actions from '../../constant/reduxActionTypes';
/*
xs  < 576px
sm  >= 576 x < 768 
md  >= 768
lg  >= 992
xl  >= 1200
xxl >= 1600
*/
export const resizeScreen = window_width => {
  return dispatch => {
    let screensize = 'xs';
    let mobile = true;
    if (window_width >= 576) {
      screensize = 'sm';
      mobile = true;
    }
    if (window_width >= 768) {
      screensize = 'md';
      mobile = false;
    }
    if (window_width >= 992) {
      screensize = 'lg';
      mobile = false;
    }
    if (window_width >= 1200) {
      screensize = 'xl';
      mobile = false;
    }
    if (window_width >= 1600) {
      screensize = 'xxl';
      mobile = false;
    }
    localStorage.setItem('app.screensize', screensize);
    localStorage.setItem('app.mobile', mobile);
    dispatch({
      type: actions.SETSCREEN_SIZE,
      screensize: screensize,
      screenwidth: window_width,
      mobile: mobile
    });
  };
};


