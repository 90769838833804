import React from 'react';
import { injectIntl } from 'react-intl';
import './Error.css';
import { messages } from './Translate';
import { getImage, openLiveChat } from '../../utils/common';

class Message extends React.Component {
  constructor() {
    super();
    this.state = {
      display: '',
      cursor: 'default',
    }
  }
  componentDidMount() {
    if (this.props.intl.locale.includes('cn') || this.props.intl.locale.includes('th')) {
      this.setState({
        disabled: false,
        cursor: 'pointer'
      })
    }
    else {
      this.setState({
        disabled: true,
        display: 'none',
        cursor: 'default'
      })
    }
  }
  render() {
    const { formatMessage } = this.props.intl;
    const country = this.props.intl.locale.split('-')[1] ? this.props.intl.locale.split('-')[1].toUpperCase() : 'CN';
    const { disabled } = this.state;

    if (this.props.errorType === 'NotFound') {
      this.logoPath = 'error/NotFound/Error404.svg';
      this.text = formatMessage(messages.NotFoundErrorText);
      this.textDesc = formatMessage(messages.NotFoundErrorTextDesc);
    }
    else if (this.props.errorType === 'AccessDenied') {
      this.logoPath = 'error/AccessDenied/Access-Denied.svg';
      this.text = formatMessage(messages.RestrictedErrorText);
      this.textDesc = formatMessage(messages.RestrictedErrorTextDesc);
    }

    return (
      <div>
        <div className="error_div">
          <section>
            <img className="error_content" src={getImage(this.logoPath)} alt="logo" />
            <label className="error_text">{this.text}</label>
          </section>
        </div>
        <div className="error_text1">
          <label >{this.textDesc}</label>
        </div>
        <div className="error_contactdetails">
          <label >{formatMessage(messages.ContactDetails)}</label>
        </div>
        <div className="error_container">
          <div className="error_contactcontainer" style={{ cursor: this.state.cursor }} onClick={openLiveChat} disabled={disabled}>
            <img className="error_contactinfo" src={getImage('error/ContactLeft_' + country + '.png')} alt="contactinfo" />
            <div className="bottomleft" style={{ display: this.state.display }}>{formatMessage(messages.ContactDetailsInstruction)}>></div>
          </div>
          <div className="error_contactcontainer1">
            <img className="error_contactinfo1" src={getImage('error/ContactRight_' + country + '.png')} alt="contactinfo" />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Message);