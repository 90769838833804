import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
    currentPeriod: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PERIOD_LIST:
            return updateObject(state, {
                currentPeriod: action.currentPeriod
            });
        default:
            return state;
    }
};

export default reducer;
