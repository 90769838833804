import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect,Router } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import LoadComponent from '../hoc/LoadComponent';
import { PrivateRoute, AnonymousOnlyRoute } from '../hoc/RouteHoc';
import NotFound from '../pages/NotFound';
import AccessDenied from '../pages/AccessDenied';
import { localeArray } from '../constant/locale';

const HomePage = LoadComponent(() => {
  return import('../pages/HomePage');
});

const AfterLoginLayout = LoadComponent(() => {
  return import('../layout/AfterLoginLayout');
});

class MainRoutes extends Component {
  render() {
    const localeList = localeArray.join('|');
    return (
      <Switch>
        <Redirect exact from={`/${process.env.REACT_APP_SUB_FOLDER}`} to={`/${this.props.intl.locale}`} />
        <Route path={`/:lang(${localeList})/error/notfound`} render={props => <NotFound {...props} />} /> 
        <Route path={`/:lang(${localeList})/error/restricted`} render={props => <AccessDenied {...props} />} />
        <AnonymousOnlyRoute
          exact path={`/:lang(${localeList})`}
          errorUri={'/dashboard'}
          locale={this.props.intl.locale}
          toRender={props => <HomePage {...props} />}
        />
        <PrivateRoute
          path={`/:lang(${localeList})`}
          locale={this.props.intl.locale}
          toRender={props => <AfterLoginLayout {...props} />}
        />
        <Redirect to={`/${this.props.intl.locale}/error/notfound`} />
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return {
    mobile: state.responsiveui.mobile
  };
};

export default injectIntl(connect(mapStateToProps)(MainRoutes));
