import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  showLanguagePopUp: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_LANGUAGE_POPUP:
      return updateObject(state, {
        showLanguagePopUp: true
      });
      case actions.CLOSE_LANGUAGE_POPUP:
        return updateObject(state, {
          showLanguagePopUp: false
        });
    default:
      return state;
  }
};

export default reducer;
