import {
    defineMessages
} from 'react-intl';

export const messages = defineMessages({
    "Step1Description": {
        "id": "Transfer.Step1Description",
        "defaultMessage": "Step 1 : Enter the transfer details below"
    },
    "Step2Description": {
        "id": "Transfer.Step2Description",
        "defaultMessage": "Step 2 : Confirm the details or go back to make changes"
    },
    "Next": {
        "id": "Transfer.Next",
        "defaultMessage": "Next"
    },
    "Confirm": {
        "id": "Transfer.Confirm",
        "defaultMessage": "Confirm"
    },
    "Back": {
        "id": "Transfer.Back",
        "defaultMessage": "Back"
    },
    "MemberCode": {
        "id": "Transfer.MemberCode",
        "defaultMessage": "Member Code"
    },
    "MemberCodeDesc": {
        "id": "Transfer.MemberCodeDesc",
        "defaultMessage": "Please choose the member code you would like to transfer"
    },
    "MemberCode_Required": {
        "id": "Transfer.MemberCode_Required",
        "defaultMessage": "Member Code cannot be blank"
    },
    "MemberCode_PlaceHolder": {
        "id": "Transfer.MemberCode_PlaceHolder",
        "defaultMessage": "Enter the member code here"
    },
    "Amount": {
        "id": "Transfer.Amount",
        "defaultMessage": "Transfer Amount"
    },
    "Amount_Required": {
        "id": "Transfer.Amount_Required",
        "defaultMessage": "Transfer amount cannot be blank"
    },
    "Amount_Format": {
        "id": "Transfer.Amount_Format",
        "defaultMessage": "Please enter valid transfer amount"
    },
    "Amount_PlaceHolder": {
        "id": "Transfer.Amount_PlaceHolder",
        "defaultMessage": "Enter the amount here"
    },
    "Amount_Min": {
        "id": "Transfer.Amount_Min",
        "defaultMessage": "Minimum Transfer amount is {currency} {value}"
    },
    "Amount_Max": {
        "id": "Transfer.Amount_Max",
        "defaultMessage": "Maximum Transfer amount is {currency} {value}"
    },
    "Amount_InsufficientBalance": {
        "id": "Transfer.Amount_InsufficientBalance",
        "defaultMessage": "Balance is not enough"
    },
    "Remarks": {
        "id": "Transfer.Remarks",
        "defaultMessage": "Remarks (optional)"
    },
    "Remarks_PlaceHolder": {
        "id": "Transfer.Remarks_PlaceHolder",
        "defaultMessage": "Enter the remarks"
    },
    "TransferSuccessful": {
        "id": "Transfer.TransferSuccessful",
        "defaultMessage": "You have succesfully transferred your balance to"
    },
    "MakeNewTransfer": {
        "id": "Transfer.MakeNewTransfer",
        "defaultMessage": "Make A New Transfer"
    },
    "ViewHistory": {
        "id": "Transfer.ViewHistory",
        "defaultMessage": "View History"
    },
    "TransferFail": {
        "id": "Transfer.TransferFail",
        "defaultMessage": "Failed to transfer to"
    },
    "SubmitAgain": {
        "id": "Transfer.SubmitAgain",
        "defaultMessage": "Submit Again"
    },
    "TransferPending": {
        "id": "Transfer.TransferPending",
        "defaultMessage": "System is processing the balance transfer to"
    },
    "TransferPending_Desc2": {
        "id": "Transfer.TransferPending_Desc2",
        "defaultMessage": "Kindly contact our support team if the transfer status still in Pending status in Transaction History"
    },
    "MemberUsername": {
        "id": "Transfer.MemberUsername",
        "defaultMessage": "Username"
    },
    "RegistrationDate": {
        "id": "Transfer.RegistrationDate",
        "defaultMessage": "Registration Date"
    },
    "No": {
        "id": "Transfer.No",
        "defaultMessage": "No"
    },
    "Search": {
        "id": "Transfer.Search",
        "defaultMessage": "Search"
    },
    "SelectRecord": {
        "id": "Transfer.SelectRecord",
        "defaultMessage": "Please click on the member record you would like to transfer"
    },
    "NoRecord": {
        "id": "Transfer.NoRecord",
        "defaultMessage": "No record."
    },
    "Records": {
        "id": "Transfer.Records",
        "defaultMesage": "Records"
    },
})