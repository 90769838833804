import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  showTutorialPopUp: false,
  tutorialType: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_TUTORIAL_POPUP:
      return updateObject(state, {
        showTutorialPopUp: true,
        tutorialType: action.tutorialType
      });
    case actions.CLOSE_TUTORIAL_POPUP:
      return updateObject(state, {
        showTutorialPopUp: false,
        tutorialType: ''
      });
    default:
      return state;
  }
};

export default reducer;
