import React from 'react';
import { Spin } from 'antd';
import { injectIntl } from 'react-intl';
import Slider from 'react-slick';
import Tappable from 'react-tappable';
import { getImage } from '../../utils/common';
import { connect } from 'react-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './Carousel.css';

class TutorialCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    })
  }

  render() {
    return (
        <div className="tutorial-screen">
            <Spin size="large" spinning={this.state.loading} style={{ color: '#fcc', fontSize: '20px' }}>
            <Slider {...this.props.settings}>
            {this.props.banners.map(
                (item, index) =>
                this.props.mobile ? (
                    <Tappable
                    key={index}
                    onTap={e => !this.props.dragging}
                    >
                    <div key={index} className="tutorial-imgdivmobile">
                        <img
                        className="tutorial-imgstyle"
                        src={getImage(item.src)}
                        alt={item.cmsImageAlt}
                        />
                    </div>
                    <div className="tutorial-descdivmobile">
                        <div className="tutorial-titlemobile">
                            {item.tutorialtitle.split('\n').map(n =><label>{n} <br /></label>)}
                        </div>
                        <div className="tutorial-titledescmobile">
                            {item.tutorialdesc.split('\n').map(n =><label>{n} <br /></label>)}
                        </div>
                        <br/>
                    </div>
                    </Tappable>
                ) : (
                    <div key={index}>
                        <div className="tutorial-imgdiv"
                            style={{ cursor: 'pointer' }}
                            // onClick={e => !this.state.dragging && this.handleBannerClick(item)}
                        >
                        <img 
                           className="tutorial-imgstyle"
                           src={getImage(item.src)}
                           alt={item.cmsImageAlt}
                           style={{ maxWidth: '100%', float: 'right' }}
                       />
                       </div>
                       <div className="tutorial-descdiv">
                            <div className="tutorial-title">
                                {item.tutorialtitle.split('\n').map(n =><label>{n} <br /></label>)}
                            </div>
                            <br/>
                            <div className="tutorial-titledesc">
                                {item.tutorialdesc.split('\n').map(n =><label>{n} <br /></label>)}
                            </div>
                       </div>
                    </div>
                    )
                )}
                </Slider>
            </Spin>
        </div>
    );
  }
};

const mapStateToProps = state => {
    return {
      mobile: state.responsiveui.mobile
    };
  };

export default injectIntl(
connect(
    mapStateToProps
  )(TutorialCarousel)
);
