import 'core-js/es6/map';
import 'core-js/es6/set';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'raf/polyfill';
import '@babel/polyfill';
import 'classlist-polyfill';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'intl';
import AppIntl from './AppIntl';
import registerServiceWorker from './registerServiceWorker';
import { axiosResponseInterceptors } from './services/baseService';

axiosResponseInterceptors();
ReactDOM.render(<AppIntl />, document.getElementById('root'));
//registerServiceWorker();
