import { getPromotions } from '../../services/promotion';
import * as actions from '../../constant/reduxActionTypes';

export const getPromotionList = (module) => {
    return dispatch => {
        getPromotions(module).then(response => {
            try {
                if (response.data.result) {
                    dispatch({
                        type: actions.SET_PROMOTION_LIST,
                        promotionList: response.data.result
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }
};