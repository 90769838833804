import React, { Component } from 'react';
import './PopUp.css';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from '../../store/actions';
import './TutorialPopUp.css';
import TutorialCarousel from '../Carousel/TutorialCarousel';
import { getImage } from '../../utils/common';
import { messages } from './Translate';

class TutorialPopUp extends Component {
  constructor(props) {
    super(props);

    const { formatMessage } = this.props.intl;

    this.state = {
      banners : [{
        src: 'tutorial/step1.png',
        cmsImageAlt: '',
        tutorialtitle: formatMessage(messages.TutorialTitleStep1),
        tutorialdesc : formatMessage(messages.TutorialDescStep1)
      },
      {
        src: 'tutorial/step2.png',
        cmsImageAlt: '',
        tutorialtitle: formatMessage(messages.TutorialTitleStep2),
        tutorialdesc : formatMessage(messages.TutorialDescStep2)
      },
      {
        src: 'tutorial/step3.png',
        cmsImageAlt: '',
        tutorialtitle: formatMessage(messages.TutorialTitleStep3),
        tutorialdesc : formatMessage(messages.TutorialDescStep3)
      }],
      dragging: false,
      loading: true
    }
  }

  componentDidMount() {
    this.setState({
      loading: false
    })
  }

  CarouselSettings = {
    dots: true,
    arrows: true,
    infinite: false,
    autoplay: false,
    beforeChange: () => this.setState({ dragging: true }),
    afterChange: () => this.setState({ dragging: false })
  };

  render() {
    return (
      this.props.showTutorialPopUp ?
        <div className="ant-modal-mask">
          <div className="tutorial-overview">
            <img src={getImage("close.png")} className="popup-close-button" onClick={this.props.closeTutorialPopUp} style={{ zIndex: 999 }} alt="close" />
            <TutorialCarousel
              loading={this.state.loading}
              banners={this.state.banners}
              dragging={this.state.dragging}
              istutorial={1}
            />
          </div>
        </div>
        : null
    );
  }
};

const mapStateToProps = state => {
  return {
    showTutorialPopUp: state.tutorialPopUp.showTutorialPopUp,
    tutorialType: state.tutorialPopUp.tutorialType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeTutorialPopUp: () => dispatch(actions.closeTutorialPopUp())
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TutorialPopUp)
);