import { removeRefreshTokenTimer } from "./refresh";
import { Cookie } from "./cookie";

export const setLocalStorage_login = (data, countrycode) => {
    const expireDate = new Date(new Date().getTime() + data.accessToken.expires_in * 1000);
    localStorage.setItem('app.token', data.accessToken.access_token);
    localStorage.setItem('app.token.refresh', data.accessToken.refresh_token);
    localStorage.setItem('app.token.expires', expireDate);
    localStorage.setItem('app.profile.affiliatecode', data.accessToken.affProfile.affiliateCode);
    localStorage.setItem('app.profile.username', data.accessToken.affProfile.userName);
    localStorage.setItem('app.profile.emailAddress', data.accessToken.affProfile.email);
    localStorage.setItem('app.profile.phoneNumber', data.accessToken.affProfile.contactPrefix + data.accessToken.affProfile.telephone);
    localStorage.setItem('app.profile.lastLogin', data.accessToken.affProfile.lastLogin);
    localStorage.setItem('app.profile.earningsModel', data.accessToken.affProfile.earningsModel);
    localStorage.setItem('app.profile.cc', data.accessToken.affProfile.enableCashCard);
    localStorage.setItem('app.profile.commission', data.accessToken.affProfile.commission);
    localStorage.setItem('app.profile.enableMemberContact', data.accessToken.affProfile.enableViewMemberContactDetails);
    localStorage.setItem('app.profile.isShowWelcomePopUp', data.accessToken.affProfile.isShowWelcomePopUp);
    localStorage.setItem('app.profile.offReminder', data.accessToken.affProfile.offReminder);
    localStorage.setItem('app.profile.currency', data.accessToken.affProfile.currency);
    localStorage.setItem('app.profile.isEnableTransferToMember', data.accessToken.affProfile.isEnableTransferToMember);
    localStorage.setItem('app.profile.countrycode', countrycode);
    localStorage.setItem('app.profile.isEmptySecretQnA', (data.accessToken.affProfile.secretQuestionID === 1 && data.accessToken.affProfile.secretAnswer === data.accessToken.affProfile.userName) ? true : false);
    localStorage.setItem('app.profile.rolecode', data.accessToken.affProfile.roleCode);
    localStorage.setItem('app.profile.settlementGroupID', data.accessToken.affProfile.settlementGroupID);
    localStorage.setItem('app.profile.registrationDate', data.accessToken.affProfile.createAt);
    if (data.accessToken.affProfile.isShowTutorial) {
        Cookie.Create('firstTimeTutorial', true, 365);
    }
}

export const setLocalStorage_oldSiteLogin = (response, countrycode) => {
    localStorage.setItem('app.profile.affiliatecode', response.data.result.affiliateCode);
    localStorage.setItem('app.profile.username', response.data.result.userName);
    localStorage.setItem('app.profile.emailAddress', response.data.result.email);
    localStorage.setItem('app.profile.phoneNumber', response.data.result.contactPrefix + response.data.result.telephone);
    localStorage.setItem('app.profile.lastLogin', response.data.result.lastLogin);
    localStorage.setItem('app.profile.earningsModel', response.data.result.earningsModel);
    localStorage.setItem('app.profile.cc', response.data.result.enableCashCard);
    localStorage.setItem('app.profile.commission', response.data.result.commission);
    localStorage.setItem('app.profile.enableMemberContact', response.data.result.enableViewMemberContactDetails);
    localStorage.setItem('app.profile.isShowWelcomePopUp', response.accessToken.affProfile.isShowWelcomePopUp);
    localStorage.setItem('app.profile.countrycode', countrycode);
    localStorage.setItem('app.profile.isEnableTransferToMember', response.accessToken.affProfile.isEnableTransferToMember);
    localStorage.setItem('app.profile.isEmptySecretQnA', (response.accessToken.affProfile.secretQuestionID === 1 && response.accessToken.affProfile.secretAnswer === response.accessToken.affProfile.userName) ? true : false);
    localStorage.setItem('app.profile.settlementGroupID', response.accessToken.affProfile.settlementGroupID);
    localStorage.setItem('app.profile.registrationDate', response.accessToken.affProfile.createAt);
    if (response.data.result.isShowTutorial) {
        Cookie.Create('firstTimeTutorial', true, 365);
    }
}

export const setLocalStorage_firstLogin = (data, countrycode) => {
    const expireDate = new Date(new Date().getTime() + data.accessToken.expires_in * 1000);
    localStorage.setItem('app.token', data.accessToken.access_token);
    localStorage.setItem('app.token.refresh', data.accessToken.refresh_token);
    localStorage.setItem('app.token.expires', expireDate);
    localStorage.setItem('app.profile.affiliatecode', data.accessToken.affProfile.affiliateCode);
    localStorage.setItem('app.profile.username', data.accessToken.affProfile.userName);
    localStorage.setItem('app.profile.emailAddress', data.accessToken.affProfile.email);
    localStorage.setItem('app.profile.phoneNumber', data.accessToken.affProfile.contactPrefix + data.accessToken.affProfile.telephone);
    localStorage.setItem('app.profile.isFirstLogin', data.accessToken.affProfile.isFirstLogin);
    localStorage.setItem('app.profile.enableMemberContact', data.accessToken.affProfile.enableMemberContact);
    localStorage.setItem('app.profile.isShowWelcomePopUp', data.accessToken.affProfile.isShowWelcomePopUp);
    localStorage.setItem('app.profile.offReminder', data.accessToken.affProfile.offReminder);
    localStorage.setItem('app.profile.currency', data.accessToken.affProfile.currency);
    localStorage.setItem('app.profile.countrycode', countrycode);
    localStorage.setItem('app.profile.isEnableTransferToMember', data.accessToken.affProfile.isEnableTransferToMember);
    localStorage.setItem('app.profile.isEmptySecretQnA', (data.accessToken.affProfile.secretQuestionID === 1 && data.accessToken.affProfile.secretAnswer === data.accessToken.affProfile.userName) ? true : false);
    localStorage.setItem('app.profile.rolecode', data.accessToken.affProfile.roleCode);
    localStorage.setItem('app.profile.settlementGroupID', data.accessToken.affProfile.settlementGroupID);
    localStorage.setItem('app.profile.registrationDate', data.accessToken.affProfile.createAt);
    if (data.accessToken.affProfile.isShowTutorial) {
        Cookie.Create('firstTimeTutorial', true, 365);
    }
}

export const setLocalStorage_refreshToken = (data) => {
    const expireDate = new Date(new Date().getTime() + data.expires_in * 1000);
    localStorage.setItem('app.token', data.access_token);
    localStorage.setItem('app.token.refresh', data.refresh_token);
    localStorage.setItem('app.token.expires', expireDate);
}

export const setLocalStorage_locale = (locale) => {
    localStorage.setItem('app.locale', locale);
}

export const removeLocalStorage = () => {
    localStorage.removeItem('app.token');
    localStorage.removeItem('app.token.refresh');
    localStorage.removeItem('app.token.expires');
    localStorage.removeItem('app.profile.affiliatecode');
    localStorage.removeItem('app.profile.username');
    localStorage.removeItem('app.profile.emailAddress');
    localStorage.removeItem('app.profile.phoneNumber');
    localStorage.removeItem('app.profile.lastLogin');
    localStorage.removeItem('app.profile.earningsModel');
    localStorage.removeItem('app.profile.commission');
    localStorage.removeItem('app.profile.cc');
    localStorage.removeItem('app.profile.isFirstLogin');
    localStorage.removeItem('app.profile.enableMemberContact');
    localStorage.removeItem('app.profile.isShowWelcomePopUp');
    localStorage.removeItem('app.profile.offReminder');
    localStorage.removeItem('app.profile.currency');
    localStorage.removeItem('app.profile.countrycode');
    localStorage.removeItem('app.profile.isEmptySecretQnA');
    localStorage.removeItem('app.profile.isEnableTransferToMember');
    localStorage.removeItem('app.profile.rolecode');
    localStorage.removeItem('app.profile.cryptoCurrency');
    localStorage.removeItem('app.profile.paymentMethod');
    localStorage.removeItem('app.profile.settlementGroupID');
    localStorage.removeItem('app.profile.registrationDate');
    removeRefreshTokenTimer();
}

export const getLocaleCountry = () => {
    const country = localStorage.getItem('app.locale');
    return country === null ? 'cn' : country.slice(-2);
};

export const getLocaleLanguage = () => {
    const language = localStorage.getItem('app.locale');
    return language === null ? 'zh' : language.substr(0, 2);
};