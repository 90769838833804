import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import responsiveuiReducer from './store/reducers/responsiveui';
import loadingReducer from './store/reducers/loading';
import popUpReducer from './store/reducers/popUp';
import tutorialPopUpReducer from './store/reducers/tutorialPopUp';
import welcomePopUpReducer from './store/reducers/welcomePopUp';
import reminderPopUpReducer from './store/reducers/reminderPopUp';
import languageReducer from './store/reducers/language';
import bankReducer from './store/reducers/bank';
import accountReducer from './store/reducers/account';
import textlinkReducer from './store/reducers/textlink';
import promotionReducer from './store/reducers/promotion';
import memberLinkReducer from './store/reducers/memberLink';
import changePeriodReducer from './store/reducers/changePeriod';
import zhLocaleData from 'react-intl/locale-data/zh';
import idLocaleData from 'react-intl/locale-data/id';
import thLocaleData from 'react-intl/locale-data/th';
import viLocaleData from 'react-intl/locale-data/vi';
import translations from './i18n/locales';
import App from './App';
import { LocaleProvider } from 'antd';
import 'moment/locale/zh-cn';
import 'moment/locale/id';
import 'moment/locale/vi';
import 'moment/locale/th';
import { setLocalStorage_locale } from './utils/localStorage';
import { getAffiliateLanguage, getAffiliateLocale } from './services/affiliate';
import { localeAuth } from './utils/locale';
import { localeArray } from './constant/locale';
import { setLocalStorage_oldSiteLogin } from './utils/localStorage';
import { getAffiliateProfile } from './services/affiliate';
import { isMarketClosingDate, getCountryCodeByCurrency } from './utils/formatter';

addLocaleData(zhLocaleData);
addLocaleData(idLocaleData);
addLocaleData(thLocaleData);
addLocaleData(viLocaleData);

const defaultLanguage = 'zh';
var urlLocale = window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX] ? window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX] : '';
//redux setup store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
    responsiveui: responsiveuiReducer,
    loading: loadingReducer,
    popUp: popUpReducer,
    language: languageReducer,
    tutorialPopUp: tutorialPopUpReducer,
    welcomePopUp: welcomePopUpReducer,
    reminderPopUp: reminderPopUpReducer,
    bank: bankReducer,
    account: accountReducer,
    textlink: textlinkReducer,
    promotion: promotionReducer,
    changePeriod: changePeriodReducer,
    memberLink: memberLinkReducer
});
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

class AppIntl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            load: false,
            moment: {},
            affLocale: null
        };
    }

    getLocale = value => {
        switch (value.substr(0, 2)) {
            case 'en':
                return 'en';
            case 'zh':
                return 'zh';
            case 'id':
                return 'id';
            case 'th':
                return 'th';
            case 'vi':
                return 'vi';
            default:
                return defaultLanguage;
        }
    };

    getLocaleValue = () => {
        var locale = '';
        if (localStorage.getItem('app.token') !== null && localStorage.getItem('app.profile.countrycode') != null) {
            locale = urlLocale.split('-')[0] + '-' + localStorage.getItem('app.profile.countrycode');
        }
        else {
            locale = urlLocale;
        }

        if (isMarketClosingDate()) {
            var localeArrayExcludeID = localeArray.filter(item => item != item.endsWith('-id'))

            return localeArrayExcludeID.includes(locale) ? locale : localStorage.getItem('app.locale');
        }
        else {
            return localeArray.includes(locale) ? locale : localStorage.getItem('app.locale');
        }
    } 

    componentDidMount() {  
        if (window.location.pathname.includes('Dashboard') && window.location.search != '') {
            if (window.location.search.includes('?Token=')) {
                var token = window.location.search.split("&")[0].replace('?Token=', '');
                var expireIn = window.location.search.split("&")[1].replace('ExpiresIn=', '');
                var refreshToken = window.location.search.split("&")[2].replace('RefreshToken=', '');
                const expireDate = new Date(new Date().getTime() + expireIn * 1000);
                localStorage.setItem('app.token', token);
                localStorage.setItem('app.token.refresh', refreshToken);
                localStorage.setItem('app.token.expires', expireDate);
                localStorage.setItem('app.token.expire.in', expireIn);

                getAffiliateProfile().then(response => {
                    if (response.status === 200) {

                        setLocalStorage_oldSiteLogin(response, getCountryCodeByCurrency(response.data.accessToken.affProfile.currency));
                        window.location.href = process.env.REACT_APP_SUB_FOLDER;
                    }
                    else {
                        localStorage.removeItem('app.token', token);
                        localStorage.removeItem('app.token.refresh', refreshToken);
                        localStorage.removeItem('app.token.expires', expireDate);
                        localStorage.removeItem('app.token.expire.in', expireIn);
                        window.location.href = process.env.REACT_APP_SUB_FOLDER;
                    }
                })
                    .catch(error => {
                        console.error(error);
                        this.setState({
                            loading: false
                        })
                    });
            }
        }

        if (isMarketClosingDate()) {
            var localeArrayExcludeID = localeArray.filter(item => item != item.endsWith('-id'))

            if (localeArrayExcludeID.includes(urlLocale)) {
                setLocalStorage_locale(urlLocale);
            }
        }
        else {
            if (localeArray.includes(urlLocale)) {
                setLocalStorage_locale(urlLocale);
            }
        }
       
        if (!window.location.pathname.includes('error')) {
            getAffiliateLanguage()
                .then(response => {
                    if (response.status === 200) {
                        if (localeAuth(urlLocale)) {
                            this.getMomentLocale(urlLocale);
                            setLocalStorage_locale(urlLocale);
                            this.setState({
                                load: true
                            })
                        }
                    }
                })
                .catch(error => {
                    if (error && error.status !== 401 && error.status !== 403 && error.status !== 503) {
                        window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${urlLocale}/error/notfound`;
                    }
                })
        }
        else {
            this.setState({
                load: true
            })
        }
    }

    getMomentLocale = (value) => {
        let locale = '';
        switch (value.substr(0, 2)) {
            case 'en':
                locale = require('antd/lib/locale-provider/en_US');
                this.setState({ moment: locale });
                break;
            case 'id':
                locale = require('antd/lib/locale-provider/id_ID');
                this.setState({ moment: locale });
                break;
            case 'th':
                locale = require('antd/lib/locale-provider/th_TH');
                this.setState({ moment: locale });
                break;
            case 'vi':
                locale = require('antd/lib/locale-provider/vi_VN');
                this.setState({ moment: locale });
                break;
            default:
                locale = require('antd/lib/locale-provider/zh_CN');
                this.setState({ moment: locale });
                break;
        }
    };

    render() {
        const { moment } = this.state;
        const language = this.getLocale(urlLocale);
        const messages = translations[language];
        const localeValue = this.getLocaleValue();
        return (
            this.state.load &&
            <Provider store={store}>
                <LocaleProvider locale={moment}>
                    <IntlProvider
                        locale={localeValue}
                        key={language}
                        messages={messages}
                    >
                        <BrowserRouter basename={process.env.REACT_APP_SUB_FOLDER}>
                            <App />
                        </BrowserRouter>
                    </IntlProvider>
                </LocaleProvider>
            </Provider>
        );
    }
}

export default AppIntl;
