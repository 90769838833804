import { postRefresh } from "../services/authentication";
import { setLocalStorage_refreshToken } from "./localStorage";

let refreshTokenTimeout;

export const setRefreshTokenTimer = () => {
  const expired = new Date(localStorage.getItem('app.token.expires'));
  const timeDiff = Math.abs(expired.getTime() - new Date().getTime()) - 300000;
  refreshTokenTimeout =
    setTimeout(() => {
      callAPIRefreshToken();
    }, timeDiff);
}

const callAPIRefreshToken = () => {
  postRefresh()
    .then(response => {
      if (response.status === 200) {
        setLocalStorage_refreshToken(response.data);
        setRefreshTokenTimer();
      }
    })
    .catch(error => {
      console.error(error);
    })
    .finally(() => {
      //setRefreshTokenTimer();
    })
}

export const removeRefreshTokenTimer = () => {
  clearTimeout(refreshTokenTimeout);
}