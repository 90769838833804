import {
    defineMessages
  } from 'react-intl';
  
  export const messages = defineMessages({
    "NotFound": {
      "id": "Greet.NotFound",
      "defaultMessage": "系统暂时无法打开您寻找的页面。如果您依然无法打开您要的页面，请马上联系我们的在线客服人员。"
    },
    "DearMember": {
        "id": "Message.DearMember",
        "defaultMessage": "亲爱的会员，"
      },
      "NotFoundErrorText": {
        "id": "Message.NotFoundErrorText",
        "defaultMessage": "网页不存在"
      },
      "NotFoundErrorTextDesc": {
        "id": "Message.NotFoundErrorTextDesc",
        "defaultMessage": "您所访问的网址不存在，\n请联系代理客服"
      },
      "RestrictedErrorText": {
        "id": "Message.RestrictedErrorText",
        "defaultMessage": "登录限制"
      },
      "RestrictedErrorTextDesc": {
        "id": "Message.RestrictedErrorTextDesc",
        "defaultMessage": "您所在地区受到限制，\n无法正常游览我们的网站。\n请联系代理客服"
      },
      "LogoPath": {
        "id": "Message.LogoPath",
        "defaultMessage": "error/Fun88-Affiliate_EN.png"
      },
      "ContactDetails": {
        "id": "Message.ContactDetails",
        "defaultMessage": "Contact details: "
      },
      "ContactDetailsPath_Left": {
        "id": "Message.ContactDetailsPath_Left",
        "defaultMessage": "error/ContactLeft_CN.png"
      },
      "ContactDetailsPath_Right": {
        "id": "Message.ContactDetailsPath_Right",
        "defaultMessage": "error/ContactRight_CN.png"
      },
      "ContactDetailsInstruction": {
        "id": "Message.ContactDetailsInstruction",
        "defaultMessage": "点击此处"
      }

  })