import axios from 'axios';
import { removeLocalStorage } from '../utils/localStorage';
import { postLogout } from '../services/authentication';

const logOutUserErrorCodes = ['AFF25007', 'AFF25009', 'AFF25013']
var isRedirecting = false;

export async function axiosResponseInterceptors() {
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      // forcelogout if hit specific errors
      if (response && response.data && logOutUserErrorCodes.includes(response.data.errorCode)) {
        localStorage.setItem('app.locked', response.data.errorCode);
        postLogout().finally(() => {
            removeLocalStorage();
            // Remove Cache when Logout
            // try{
            //   caches.keys().then(function(names){
            //   names = "revamp-pwa";
            //   caches.delete(names);
            //   });
            // }catch{
            //   console.log('No cache');
            // }
            //alert("test3 " + window.location.pathname);
            const locale = window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX] || 'zh-cn';
            window.location.href = `${process.env.REACT_APP_SUB_FOLDER}/${locale}`;
        })
      } else {
        return response;
      }
    },
    function (error) {
      if (error.response) {
        const locale = window.location.pathname.split('/')[process.env.REACT_APP_LOCALE_INDEX] || 'zh-cn';
        baseResponseFilter(error.response.status, locale);
      } 
      // else if (error.message) {
      //   console.error(error.message);
      //   if (error.message === 'Network Error') {
      //     windowRedirection('/en-cn/error/notfound');
      //   }
      // }
      return Promise.reject(error);
    }
  );
}

//TODO: multiple api call will cause multiple redirection
export function baseResponseFilter(status, locale) {
  switch (status) {
    case 200 /* OK */:
      break;
    case 302 /* Found  */:
      break;
    case 401 /* unauthorized */:
      removeLocalStorage();
      windowRedirection(`/${locale}`); /*to home page instead of error page*/
      break;
    case 403 /* forbidden */:
      windowRedirection('/en-cn/error/restricted'); /*ip restricted page*/
      break;
    case 400 /* bad request*/:
    case 404 /* not Found */:
    case 408 /* timeout */:
    case 500 /* internal server error */:
    case 502 /* Bad Gateway */:
      break;
    case 503 /* service unavailable/site maintenance */:
      windowRedirection(`/${locale}`); /*to maintenance page (IIS point maintenance folder)*/
      break;
    default:
      break;
  }
}

function windowRedirection(url) {
  if (!isRedirecting && !window.location.pathname.toLowerCase().includes('error')) {
    isRedirecting = true;
    window.location = url;
  }
}
