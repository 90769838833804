import * as actions from '../../constant/reduxActionTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  showLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_LOADING:
      return updateObject(state, {
        showLoading: action.showLoading
      });
    default:
      return state;
  }
};

export default reducer;
