import { updateObject } from '../../utils/common';
import * as actions from '../../constant/reduxActionTypes';

const initialState = {
    balance: 0.00
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_BALANCE:
            return updateObject(state, {
                balance: action.balance
            });
        default:
            return state;
    }
};

export default reducer;