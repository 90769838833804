import {
    defineMessages
} from 'react-intl';

export const messages = defineMessages({
    "Language": {
        "id": "NavLanguage.Language",
        "defaultMessage": "Language"
    },
    "Country": {
        "id": "NavLanguage.Market",
        "defaultMessage": "Market"
    },
    "china": {
        "id": "Country.China",
        "defaultMessage": "China"
    },
    "indonesia": {
        "id": "Country.Indonesia",
        "defaultMessage": "Indonesia"
    },
    "thailand": {
        "id": "Country.Thailand",
        "defaultMessage": "Thailand"
    },
    "vietnam": {
        "id": "Country.Vietnam",
        "defaultMessage": "Vietnam"
    },
    "Select" : {
        "id": "Common.Select",
        "defaultMessage": "Select"
    }
})
